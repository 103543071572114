import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleRightDrawer } from "../../actions/actionCreators";
import progress_icon from "../../img/progress_icon.png";
import moment from "moment";
import { getProduct } from "../../actions/products-actions/actions";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import LazyImg from "../Library/LazyImage";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import Pdf from "../../icons/Pdf";
interface Props {
  notif: any;
  inPage: boolean;
  fabric?: boolean;
  position: boolean;
}

export const ConfirmStep = ({ notif, inPage, fabric, position }: Props) => {
  const dispatch = useDispatch();
  const { user, isLoading, isAdmin } = useSelector((state: any) => state.auth);

  return inPage ? (
    <>

     <Link
     to={"#"}
       target="_blank"
     >
       <article className="notif_selection order_changes">
         <div className="notif_details flex-grow-1">
           <h2 className="h4_medium mb-0">Congratulations! Your order has been confirmed and we started to work on it 📫</h2>
           <div className="d-flex flex-column">
           <p className="selection_date mb-0" style={{ fontSize: 18 }}>
              {notif?.data?.name }
            </p>
             <p
               className="selection_date align-self-end"
               style={{ fontSize: "0.7rem", fontWeight: 400 }}
             >
               {moment(notif.created_at).fromNow()}
             </p>
           </div>
         </div>
       </article>
     </Link> 
     </>
  ) : (
 
    <div
    className="border my-2 px-4 py-3 border"
    style={{ borderRadius: "8px" }}
  >

    <>

     <Link
     to={"#"}
       target="_blank"
     >
       <article className="notif_selection order_changes">
         <div className="notif_details flex-grow-1">
           <h2 className="h4_medium mb-0">Congratulations! Your order has been confirmed and we started to work on it 📫</h2>
           <div className="d-flex flex-column">
           <p className="selection_date mb-0" style={{ fontSize: 18 }}>
              {notif?.data?.name }
            </p>
             <p
               className="selection_date align-self-end"
               style={{ fontSize: "0.7rem", fontWeight: 400 }}
             >
               {moment(notif.created_at).fromNow()}
             </p>
           </div>
         </div>
       </article>
     </Link> 
     </>
    
  </div>
  );
};
