import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "antd";
import moment from "moment";
import img360 from "../../img/360.png";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { useDispatch } from "react-redux";
import LazyImg from "../Library/LazyImage";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ProductForYouAdded({ inPage, notif, position }: any) {
  const dispatch = useDispatch();
  const [img, setImg] = useState<any>(null);
  const [prod, setProd] = useState<any>(null);

  useEffect(() => {
    
      axios
        .get(process.env.REACT_APP_API_URL + `/products/${notif.data.id}`)
        .then((res: any) => setProd(res.data))
        .catch((err) => {});
 
  }, []);

  useEffect(() => {
    if (prod) {
      setImg(prod.images[0] && prod.images[0].path);
    }
  }, [prod]);
  
  const imageToDisplay = () => {
   
      if (img) {
        return process.env.REACT_APP_STORAGE_URL + img;
      } else {
        return placeholder_jeans;
      }
    
  };
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
        <LazyImg
          src={imageToDisplay()}
          style={{
            width: "5.5rem",
            height: "5.5rem",
            objectFit: "cover",
            borderRadius: "8px",
          }}
          className="added_product_img"
          alt={notif.data.name}
        />
        </div>
        <div>
           <h2 className="h4_medium mb-1" style={{ fontWeight: 600 }}>
           Your dedicated FOR YOU selection is ready 🤩
          </h2> 

        
        </div>
      </div>
      <div className="d-flex flex-column align-items-start">
     {/*    <button className="light_button m-0 ml-0 mr-3 p-0">
          <Link to={"#"} className="light_button" style={{ fontSize: "1rem" }}>
            See details
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Link>
        </button> */}
        <p className="selection_date m-0" style={{ fontSize: "0.7rem" }}>
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}>
      <article className="notif_selection order_changes w-100">
        <div>
        <LazyImg
          src={imageToDisplay()}
          style={{
            width: "5.5rem",
            height: "5.5rem",
            objectFit: "cover",
            borderRadius: "8px",
          }}
          className="added_product_img"
          alt={notif.data.name}
        />
        </div>
        <div className="notif_details" style={{ flex: 1 }}>
         
        <h2 className="h4_medium mb-1" style={{ fontWeight: 600 }}>
        Your dedicated FOR YOU selection is ready 🤩

          
          </h2> 
          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
            {/* <Link
              to={`/single-post/${notif?.data?.id}`}
              className="light_button align-self-end"
              style={{ fontSize: "0.8rem" }}
              onClick={() => dispatch(handleRightDrawer())}>
              See details
              <small>
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 " />
              </small>
            </Link> */}
            <p
              className="selection_date mb-0 mt-3"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}>
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
}
