import { Avatar } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DirectOrderCreated = ({ inPage, notif, position }: any) => {
  const dispatch = useDispatch();
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
          {
            <Avatar
              src={
                notif.data.client.logo
                  ? process.env.REACT_APP_STORAGE_URL + notif.data.client.logo
                  : ""
              }
              className="order_sent_notif_icon"
            />
          }
        </div>
        <h2 className="h4_medium m-0">
          {`Your client ${notif.data.client.name} `}
          created a new direct order{" "}
          <span style={{ fontWeight: "lighter" }}>{notif.data.name}</span>
        </h2>
      </div>
      <button className="light_button m-0 ml-auto mr-3">
        <Link
          to={`/edit-order/${notif.data.id}`}
          className="light_button"
          style={{ fontSize: "1rem" }}
        >
          See details
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Link>
      </button>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes w-100">
        <div>
          <Avatar
            src={
              notif.data.client.logo
                ? process.env.REACT_APP_STORAGE_URL + notif.data.client.logo
                : ""
            }
            className="order_sent_notif_icon"
            size={"large"}
          />
        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          <h2 className="h4_medium">
            {`Your client ${notif.data.client.name} `}
            created a new direct order{" "}
            <span style={{ fontWeight: "lighter" }}>{notif.data.name}</span>
          </h2>

          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
            <Link
              to={`/edit-order/${notif.data.id}`}
              className="light_button align-self-end"
              style={{ fontSize: "0.8rem" }}
              onClick={() => dispatch(handleRightDrawer())}
            >
              See details
              <small>
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 " />
              </small>
            </Link>
            <p
              className="selection_date mb-0"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default DirectOrderCreated;
