import {
  Row,
  Col,
  Card,
  Pagination,
  Result,
  Button,
  Popconfirm,
  Modal,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoadingLibraryContent from "./LoadingLibraryContent";
import LazyImg from "./LazyImage";
import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";
import { HrIcon } from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../Spinner";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { useDropzone } from "react-dropzone";
import no_result from "../../img/no_result.png";
import { v4 as uuidv4 } from "uuid";

const { Meta } = Card;

const Content = ({
  content,
  setSelectedItem,
  isLoading,
  isFetching,
  addNewFabric,
}: any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [dataToMap, setDataToMap] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();
  const [url, setUrl] = useState(
    `/fabrics?fabric_type=${query.get(
      "fabric_type"
    )}&page=${currentPage}&limit=10`
  );

  useEffect(() => {
    const url = `/fabrics?fabric_type=${query.get(
      "fabric_type"
    )}&page=${currentPage}&limit=10`;
    fetchData(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  const location = useLocation(); // Used to get the current location.

  const getFabricTypeFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get("fabric_type");
  };

  useEffect(() => {
    setCurrentPage(1); // Reset page to 1
    setDataToMap([]); // Clear current data
    fetchData(1); // Fetch new data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]); // This makes useEffect listen for changes in the search part of the URL.

  const fetchData = (page: any) => {
    setLoading(true);

    let token = localStorage.getItem("token");

    const url = `/fabrics?fabric_type=${query.get(
      "fabric_type"
    )}&page=${page}&limit=10`;

    return axiosInstance({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.data.data && response.data.data.length > 0) {
        setDataToMap((prevDataToMap: any) => [
          ...prevDataToMap,
          ...response.data.data,
        ]);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    });
  };
  /* const { data, refetch } = useQuery(
    ["content", query.get("fabric_type"), url],
    () => fetchData(url),
    {
      keepPreviousData: true,
    }
  );*/

  console.log(content?.data);
  const deleteFabric = (id: any) => {
    let token = localStorage.getItem("token");
    console.log(id);

    return axiosInstance({
      method: "delete",
      url: `deleteFabrics/` + id,
      // data: { ...data, _method: "post", typeFilter },
      data: id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async () => {
        //  await refetch();
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleLoadMore = () => {
    fetchData(currentPage + 1);

    setCurrentPage(currentPage + 1);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (query: any) => {
    const lowerCaseQuery = query.toLowerCase();
    console.log(lowerCaseQuery);
    const filteredItems = content.data.filter((item: any) =>
      item.name.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filteredItems);
    setSearchQuery(query);
  };

  // Update the data source for the map function based on the search results.

  /*
const dataToMap = searchQuery ? filteredData : content?.data;
*/

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenImage, setIsModalOpenImage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const containerRef = useRef<any>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".xlsx",

    maxFiles: 1,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),
  });

  const containerRefImage = useRef<any>();
  const {
    acceptedFiles: acceptedFilesImage,
    getRootProps: getRootPropsImage,
    getInputProps: getInputPropsImgae,
  } = useDropzone({
    accept: ".zip",

    maxFiles: 1,
    onDragEnter: () => (containerRefImage.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRefImage.current.style.background = "#f4f7ff"),
  });

  const files = acceptedFiles.map((file: any) => (
    <div key={file.path}>{file.path}</div>
  ));

  const filesImage = acceptedFilesImage.map((file: any) => (
    <div key={file.path}>{file.path}</div>
  ));
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalImage = () => {
    setIsModalOpenImage(true);
  };

  const [type, setType] = useState("denim");

  const handleOk = () => {
    let token = localStorage.getItem("token");

    if (acceptedFiles.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append("file", acceptedFiles[0]);
      formData.append("type", type);
      if (type == "denim") {
        // Replace 'YOUR_API_ENDPOINT' with the actual endpoint
        axiosInstance
          .post("/import-fabric-denim", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // Handle the successful upload response here
            setIsModalOpen(false);
            fetchData(1);
            setConfirmLoading(false);

            MyNotif("Fabric imported successfully.", "success");
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error uploading file:", error);
          });
      } else {
        axiosInstance
          .post("/import-fabric-knit", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // Handle the successful upload response here
            setIsModalOpen(false);
            fetchData(1);
            setConfirmLoading(false);

            MyNotif("Fabric imported successfully.", "success");
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error uploading file:", error);
          });
      }
    }
  };

  const handleOkImage = () => {
    let token = localStorage.getItem("token");

    if (acceptedFilesImage.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append("file", acceptedFilesImage[0]);
      formData.append("type", type);

      axiosInstance
        .post("/import-fabric-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Handle the successful upload response here
          setIsModalOpenImage(false);
          fetchData(1);
          setConfirmLoading(false);

          MyNotif("Fabric imported successfully.", "success");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error uploading file:", error);
        });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelImage = () => {
    setIsModalOpenImage(false);
  };

  const handleChangeSelect = (value: any) => {
    setType(value);
  };

  // @ts-ignore
  return (
    <div style={{ background: "transparent" }}>
      <Row justify="space-between" style={{ paddingTop: "15px" }}>
        <div>
          <h2 className={"list_of_companies_title"}>DEMCO’s library</h2>
          {user && isAdmin && (
            <div className=" ">
              <Button
                type="default"
                className="approve_btn"
                onClick={() => {
                  addNewFabric();
                }}
              >
                Add new fabric
              </Button>
            </div>
          )}
        </div>{" "}
        {user && isAdmin && (
          <>
            {/*            <div className="proto_footer_btns ">
                <Button
                    type="default"
                    className="approve_btn"
                    onClick={() => {
                      addNewFabric();
                    }}
                >
                Add new fabric
                </Button>
              </div>*/}

            <div
              className={" row"}
              style={{ alignItems: "center", display: "flex", gap: "10px" }}
            >
              <button
                onClick={showModal}
                className={"view_history"}
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Upload Sheet
                <FileExcelOutlined />
              </button>

              <Modal
                title="Upload your file"
                okText="Upload"
                confirmLoading={confirmLoading}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Select
                  onChange={handleChangeSelect}
                  style={{ width: "100%" }}
                  defaultValue={"denim"}
                  size={"large"}
                  className={"select_add_product"}
                >
                  <option value="denim" selected>
                    Denim
                  </option>
                  <option value="knit">Knit</option>
                </Select>
                <section className="container" style={{ marginTop: "25px" }}>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {acceptedFiles.length > 0 ? (
                      <>
                        <ul>{files}</ul>
                      </>
                    ) : (
                      <>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </>
                    )}
                  </div>
                </section>
              </Modal>

              <button
                onClick={showModalImage}
                className={"view_history"}
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                }}
              >
                Upload Image <FileZipOutlined />
              </button>

              <Modal
                title="Upload your file Zip"
                okText="Upload"
                confirmLoading={confirmLoading}
                visible={isModalOpenImage}
                onOk={handleOkImage}
                onCancel={handleCancelImage}
              >
                <Select
                  onChange={handleChangeSelect}
                  style={{ width: "100%" }}
                  defaultValue={"denim"}
                  size={"large"}
                  className={"select_add_product"}
                >
                  <option value="denim" selected>
                    Denim
                  </option>
                  <option value="knit">Knit</option>
                </Select>
                <section className="container" style={{ marginTop: "25px" }}>
                  <div {...getRootPropsImage({ className: "dropzone" })}>
                    <input {...getInputPropsImgae()} />
                    {acceptedFilesImage.length > 0 ? (
                      <>
                        <ul>{filesImage}</ul>
                      </>
                    ) : (
                      <>
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </>
                    )}
                  </div>
                </section>
              </Modal>
            </div>
          </>
        )}
      </Row>
      <div className="row mt-4 mb-4">
        {/* <input
        type="text"
        placeholder="Search your fabric..."
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="option_details_input"
      />*/}

        <div className="search-bar searchfaq" style={{ width: "100%" }}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Search your fabric..."
              onChange={(e) => handleSearch(e.target.value)}
              className={"search_input"}
            />
            <HrIcon></HrIcon>
            <button type="submit" className={"submit_input"}>
              <SearchOutlined />
            </button>
          </div>
        </div>
      </div>
      <Row gutter={15}>
        {isLoading || isFetching ? (
          // @ts-ignore
          <LoadingLibraryContent />
        ) : content && content?.data?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            {/* <Result
                  status="404"
                  title="No fabric was found"
                  subTitle="There is no fabric that muches your search."
              />*/}

            <img src={no_result} />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={dataToMap.length}
              next={handleLoadMore}
              hasMore={hasMore}
              loader={""}
              endMessage={
                <div style={{ textAlign: "center" }}>You have seen it all</div>
              }
            >
              <Row gutter={15}>
                {dataToMap.map((item: any, index: any) => (
                  <Col
                    className="gutter-row"
                    key={uuidv4()}
                    xs={12}
                    sm={8}
                    lg={6}
                    xl={4}
                    style={{ marginTop: 15 }}
                  >
                    <Card
                      style={{ cursor: "pointer" }}
                      bodyStyle={{ padding: "15px 0" }}
                      className="library-card"
                      bordered={false}
                      key={uuidv4()}
                      cover={
                        <LazyImg
                          alt={item.id}
                          src={
                            item.images[0]?.image
                              ? process.env.REACT_APP_STORAGE_URL +
                                item.images[0]?.image
                              : null
                          }
                          thumb={
                            item.images[0]?.image
                              ? process.env.REACT_APP_STORAGE_URL +
                                item.images[0]?.image
                              : null
                          }
                          style={{
                            objectFit: "cover",
                            borderRadius: 0,
                            height: 263,
                          }}
                        />
                      }
                      actions={
                        user &&
                        isAdmin &&
                        // user.type !== "commercial" &&
                        user.permissions.includes("fabric-library.update") && [
                          <EditOutlined
                            key="edit"
                            width="100%"
                            height="100%"
                            onClick={() => setSelectedItem(item)}
                          />,

                          <Popconfirm
                            placement="top"
                            title={
                              "Are you sure you want to delete this fabric ?"
                            }
                            onConfirm={() => deleteFabric(item.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              key="delete"
                              width="100%"
                              height="100%"
                              //  onClick={() => setSelectedItem(item)}
                            />
                          </Popconfirm>,
                        ]
                      }
                    >
                      <Meta
                        title={
                          <Link
                            to={`/library/${item.id}`}
                            className="fabric-name-link"
                          >
                            {item.name}
                          </Link>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>

            {/*    {dataToMap?.map((item: any) => (
                  <Col
                      className="gutter-row"
                      key={item.id}
                      xs={12}
                      sm={8}
                      lg={6}
                      xl={4}
                      style={{marginTop: 15}}
                  >

                    <Card
                        style={{cursor: "pointer"}}
                        bodyStyle={{padding: "15px 0"}}
                        className="library-card"
                        bordered={false}
                        cover={
                          <LazyImg
                              alt={item.id}
                              src={
                                item.images[0]?.image
                                    ? process.env.REACT_APP_STORAGE_URL + item.images[0]?.image
                                    : null
                              }
                              thumb={
                                item.images[0]?.image
                                    ? process.env.REACT_APP_STORAGE_URL + item.images[0]?.image
                                    : null
                              }
                              style={{
                                objectFit: "cover",
                                borderRadius: 0,
                                height: 263,
                              }}
                          />
                        }
                        actions={
                            user &&
                            isAdmin &&
                            // user.type !== "commercial" &&
                            user.permissions.includes("fabric-library.update") && [
                              <EditOutlined
                                  key="edit"
                                  width="100%"
                                  height="100%"
                                  onClick={() => setSelectedItem(item)}
                              />,

                              <Popconfirm
                                  placement="top"
                                  title={"Are you sure you want to delete this fabric ?"}
                                  onConfirm={() => deleteFabric(item.id)}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                <DeleteOutlined
                                    key="delete"
                                    width="100%"
                                    height="100%"
                                    //  onClick={() => setSelectedItem(item)}
                                />
                              </Popconfirm>,
                            ]
                        }
                    >
                      <Meta
                          title={
                            <Link
                                to={`/library/${item.id}`}
                                className="fabric-name-link"
                            >
                              {item.name}
                            </Link>
                          }
                      />
                    </Card>
                  </Col>

              ))}*/}
          </>
        )}
      </Row>
    </div>
  );
};

export default Content;
