import { Avatar } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import img360 from "../../img/360.png";

const ProtoAccepted = ({ inPage, notif, position }: any) => {
  const dispatch = useDispatch();

  const goTo = () => {
    switch (notif?.data.type) {
      case "direct order":
        return `/all-clients/${notif?.data?.company?.id}/1/${notif?.data?.id}/${notif?.data.article_id}/${notif?.data?.option_id}/progress`;
      case "demco order":
        return `/all-clients/${notif?.data?.company?.id}/2/${notif?.data?.id}/${notif?.data.product_id}/progress/order`;
      case "Customized Product":
        return `/all-clients/${notif?.data?.company?.id}/3/${notif?.data?.id}/${notif?.data?.product_id}/progress/customized`;
      default:
        return `#`;
    }
  };

  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
          {
            <Avatar
              src={img360}
              size={"large"}
              style={{
                filter: "brightness(0)",
              }}
              className="order_sent_notif_icon contain"
            />
          }
        </div>
        <div>
          <h2 className="h4_medium mb-1" style={{ fontWeight: 600 }}>
            Prototype accepted
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontWeight: 500, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                className="selection_date"
              >
                Company:{" "}
              </span>
              {notif?.data?.company?.name}
            </span>
            <span
              style={{ fontWeight: 500, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                className="selection_date"
              >
                Order reference:{" "}
              </span>
              {notif.data.reference || notif.data.ref}
            </span>
            {notif.data.type !== "direct order" && (
              <span
                style={{ fontWeight: 500, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "0.7rem",
                  }}
                  className="selection_date"
                >
                  Product name:{" "}
                </span>
                {notif.data?.product_code + " " + notif.data?.product_name}
              </span>
            )}
            {notif.data.type === "direct order" && (
              <span
                style={{ fontWeight: 500, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "0.7rem",
                  }}
                  className="selection_date"
                >
                  Option name:{" "}
                </span>
                {notif.data.option_name}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-start">
        <button className="light_button m-0 ml-0 mr-3 p-0">
          <Link to={goTo} className="light_button" style={{ fontSize: "1rem" }}>
            See details
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Link>
        </button>
        <p className="selection_date m-0" style={{ fontSize: "0.7rem" }}>
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  ) : (
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}
    >
      <article className="notif_selection order_changes w-100">
        <div>
          <Avatar
            src={img360}
            className="order_sent_notif_icon contain"
            size={"large"}
            style={{
              filter: "brightness(0)",
            }}
          />
        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          <h2 className="h4_medium mb-1">Prototype accepted</h2>
          <div className="d-flex flex-column">
            <span
              style={{ fontWeight: 300, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "0.7rem",
                }}
                className="selection_date"
              >
                Company:{" "}
              </span>
              {notif?.data?.company?.name}
            </span>
            <span
              style={{ fontWeight: 300, fontSize: "0.7rem" }}
              className="selection_date"
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "0.7rem",
                }}
                className="selection_date"
              >
                Order reference:{" "}
              </span>
              {notif.data.reference || notif.data.ref}
            </span>
            {notif.data.type !== "direct order" && (
              <span
                style={{ fontWeight: 300, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "0.7rem",
                  }}
                  className="selection_date"
                >
                  Product name:{" "}
                </span>
                {notif.data?.product_code + " " + notif.data?.product_name}
              </span>
            )}
            {notif.data.type === "direct order" && (
              <span
                style={{ fontWeight: 300, fontSize: "0.7rem" }}
                className="selection_date"
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "0.7rem",
                  }}
                  className="selection_date"
                >
                  Option name:{" "}
                </span>
                {notif.data.option_name}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
            <Link
              to={goTo}
              className="light_button align-self-end"
              style={{ fontSize: "0.8rem" }}
              onClick={() => dispatch(handleRightDrawer())}
            >
              See details
              <small>
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 " />
              </small>
            </Link>
            <p
              className="selection_date mb-0 mt-3"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ProtoAccepted;
