import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import moment from "moment";
import avatar_placeholder from "../../img/avatar_placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const InvitationCollab = ({ notif, inPage, position }: Props) => {
  const dispatch = useDispatch();
  return inPage ? (
    <Link
      to={`/edit-order/${
        notif.data.direct_order ? notif.data.direct_order.id : "#"
      }`}
      className="notif_item_page p-2 my-2"
    >
      <div className="d-flex align-items-center icon_notif_container ">
        <div
          style={{
            minWidth: 45,
            margin: "0 8px",
          }}
        >
        {/*   <img
            src={
              notif.data?.direct_order_owner?.logo
                ? process.env.REACT_APP_STORAGE_URL +
                  notif.data.direct_order_owner.logo
                : avatar_placeholder
            }
            alt="collaborator avatar"
            style={{
              height: 40,
              width: 40,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          /> */}
           <FontAwesomeIcon
            icon={faPaperPlane}
            className="order_sent_notif_icon"
          />
        </div>

       {/*  <h2 className="h4_medium mb-0 mx-2">
          {notif.data.direct_order_owner
            ? notif.data.direct_order_owner.name
            : "User"}{" "}
          wants you to collaborate on {notif.type}
        </h2> */}
        <h2 className="h4_medium mb-0">
You succesfully launched a new order, we will confirm it as soon as possible 🛍️
            </h2>
        <p className="notif_name mb-0" style={{ fontSize: 18 }}>
       {/*    {notif.data.direct_order
            ? notif.data.direct_order.name
            : "This Order"} */}
        </p>
      </div>

      <p className="selection_date mb-0">
        {moment(notif.created_at).fromNow()}
      </p>
    </Link>
  ) : (
    <div
      className="border my-2 px-4 py-3 border"
      style={{ borderRadius: "8px" }}
    >
      <Link
        to={`/edit-order/${
          notif.data.direct_order ? notif.data.direct_order.id : "#"
        }`}
        className="notif_added_prod_container"
        onClick={() => dispatch(handleRightDrawer())}
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >
        <article className="notif_selection order_changes">
         {/*  <img
            src={
              notif.data?.direct_order_owner?.logo
                ? process.env.REACT_APP_STORAGE_URL +
                  notif.data.direct_order_owner.logo
                : avatar_placeholder
            }
            alt="collaborator avatar"
            style={{
              height: 40,
              width: 40,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          /> */}  <FontAwesomeIcon
            icon={faPaperPlane}
            className="order_sent_notif_icon"
          />
          <div className="notif_details flex-grow-1">
         {/*    <h2 className="h4_medium mb-0">
              {notif.data.direct_order_owner
                ? notif.data.direct_order_owner.name
                : "User"}{" "}
              wants you to collaborate on
            </h2> */}

<h2 className="h4_medium mb-0">
You succesfully launched a new order, we will confirm it as soon as possible 🛍️
            </h2>
            <div className="d-flex flex-column">
              <p className="selection_date mb-0" style={{ fontSize: 18 }}>
             {/*    {notif.data.direct_order
                  ? notif.data.direct_order.name
                  : "This Order"} */}
              </p>
              <p
                className="selection_date align-self-end"
                style={{ fontSize: "0.7rem", fontWeight: 400 }}
              >
                {moment(notif.created_at).fromNow()}
              </p>
            </div>
          </div>
        </article>
      </Link>
    </div>
  );
};
