import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { Swiper, SwiperSlide } from "swiper/react";
import {Link, useHistory} from "react-router-dom";
import { Card } from "antd";
import placeholder_jeans from "../../img/placeholder_jeans.svg";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
/* import SwiperCore from "swiper/react/swiper-react";
 */ import { Pagination, Navigation, Keyboard } from "swiper";

import { Skeleton } from "antd";
import { ImgItem } from "../MasonryBlock/ImgItem";

const { Meta } = Card;
// install Swiper modules
/* SwiperCore.use([Pagination, Navigation, Keyboard]);
 */
const HorizontalProducts = ({ data, isLoading, fabric, type }: any) => {
  const history = useHistory();

  console.log(data);
  return isLoading ? (
    <Swiper className="mySwiper" slidesPerView={4} spaceBetween={30}>
      {Array.from({ length: 4 }, (_, index) => index + 1).map((_, i) => (
        <SwiperSlide className="d-flex flex-column skeleton_swiper" key={i}>
          <Card
            loading={isLoading}
            bordered={false}
            style={{ width: 200 }}
            cover={
              <img
                style={{
                  width: "100%",
                  height: 200,
                  objectFit: "contain",
                }}
                src={placeholder_jeans}
              />
            }
          >
            <Meta title={"Code"} description={"Name"} />
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : data ? (
    data.length !== 0 ? (
      <div
        className={
          type !== "similar" ? "swiper_similar" : "swiper_similar_first"
        }
      >
        <h4 className="total_price_title similar_products_title">
          {type === "similar"
            ? fabric === "denim"
              ? "SAME FABRIC DIFFERENT WASH"
              : "SIMILAR PRODUCTS"
            : "TOTAL LOOK"}
        </h4>
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          navigation={true}
          keyboard={true}
          pagination={{
            clickable: true,
            bulletClass: "bullet_products",
          }}
          breakpoints={{ 360: {  width: 360,slidesPerView: 1 },
              768: {
                  width: 768,
                  slidesPerView: 3.5,
              },}}
          className="mySwiper"
        >
          {data &&
            data.map((x: any, i: number) => (
              /*<>
                <SwiperSlide
                  className="swiper_container_products"
                  key={i}
                  onClick={() =>
                    history.push(`/product/details/${x.name}/${x.id}`)
                  }
                >
                  <Card
                    bordered={false}
                    style={{ width: 200 }}
                    cover={
                      <img
                        style={{
                          width: "100%",
                          height: 200,
                          objectFit: "contain",
                        }}
                        src={
                          process.env.REACT_APP_STORAGE_URL + x?.front_image ||
                          placeholder_jeans
                        }
                      />
                    }
                  >
                    <Meta
                      title={
                        <p style={{ fontWeight: 300, whiteSpace: "pre-wrap" }}>
                          <p style={{ fontWeight: 500, marginBottom: "0px" }}>
                            {x.code || "CODE"}
                          </p>
                          {x.name}
                        </p>
                      }
                    />
                  </Card>
                  {/!* <div className="img_wrapper_swiper_products">
                    <ImgItem
                      src={
                        process.env.REACT_APP_STORAGE_URL + x.images[0]?.path ||
                        placeholder_jeans
                      }
                      alt={x.name + " " + x.code}
                      img_class="w-100"
                    />
                    <ImgItem
                      src={
                        process.env.REACT_APP_STORAGE_URL + x.images[1]?.path ||
                        placeholder_jeans
                      }
                      alt={x.name + " " + x.code}
                      img_class="w-100 back_img_similar"
                    />
                  </div>
                  <p
                    className="product_name_similar mb-0"
                    style={{ fontWeight: "bold" }}
                  >
                    {x.code}
                  </p>
                  <p className="product_name_similar">{x.name}</p> *!/}
                </SwiperSlide>
              </>*/
                <>
                    <SwiperSlide
                        className="sliderHeight">
                        <div  style={{textAlign:"center",width:"250px" }}>
                            <Link
                                to={`/product/details/${x.fabric}/${x.gendre}/${x.category}/${x.name}/${x.id}`}
                                style={{
                                    textAlign: "center",
                                    color:"#182844"
                                }}
                            >
                                <img
                                    src={
                                        x.front_image
                                            ? process.env
                                                .REACT_APP_STORAGE_URL +
                                            x.front_image
                                            : placeholder_jeans
                                    }
                                    width="270"
                                    height="300"
                                    /*
                                                                                                                                        className={"img-responsible-choice"}
                                    */
                                />
                                <p
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        textTransform:"capitalize"
                                    }}
                                >
                                    {x.code} <br/>
                                    {x.name}
                                    <>
                                        {x.gender_id == 2 ? (
                                            <>-Women</>
                                        ) : x.gender_id ==
                                        1 ? (
                                            <>-Men</>
                                        ) : x.gender_id ==
                                        3 ? (
                                            <>-Unisex</>
                                        ) : x.gender_id ==
                                        4 ? (
                                            <>-Kids</>
                                        ) : x.gender_id ==
                                        5 ? (
                                            <>-Boys</>
                                        ) : x.gender_id ==
                                        6 ? (
                                            <>-Girls</>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                </p>
                            </Link>
                        </div>
                    </SwiperSlide>
                </>
            ))}
        </Swiper>
      </div>
    ) : null
  ) : null;
};

export default HorizontalProducts;
