import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "antd";
import moment from "moment";
import img360 from "../../img/360.png";
import video from "../../img/02.png";
import trending from "../../img/03.png";
import moodboars from "../../img/01.png";


import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { useDispatch } from "react-redux";

export default function NewPost({ inPage, notif, position }: any) {
  const dispatch = useDispatch();
  return inPage ? (
    <div className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
        {console.log(notif?.data)}

        {notif.data.category == "moodboars" && (
            <Avatar
              src={moodboars}
              size={"large"}
              style={{
                filter: "brightness(0)",
              }}
              className="order_sent_notif_icon contain"
            />)
          }

      {notif.data.category == "trending" && (
                  <Avatar
                    src={trending}
                    size={"large"}
                    style={{
                      filter: "brightness(0)",
                    }}
                    className="order_sent_notif_icon contain"
                  />)
                }

      {notif.data.category == "3dvideos" && (
                  <Avatar
                    src={video}
                    size={"large"}
                    style={{
                      filter: "brightness(0)",
                    }}
                    className="order_sent_notif_icon contain"
                  />)
                }
        </div>
        <div>
          {/*   <h2 className="h4_medium mb-1" style={{ fontWeight: 600 }}>
            New creative station posted added {notif.created_at}
          </h2> */}

          {notif.data.category == "moodboars" && (
            <h2 className="text_notification mb-1">
              Moodboards are the best way to start a new season! Check out ours
              🚀{" "}
            </h2>
          )}

          {notif.data.category == "trending" && (
            <h2 className="text_notification mb-1">
              The hottest fashion gossip is here 💁💁‍♂️{" "}
            </h2>
          )}
          {notif.data.category == "3dvideos" && (
            <h2 className="text_notification mb-1">Check out our newest 3D video 👽</h2>
          )}
        </div>
      </div>
      <div className="d-flex flex-column align-items-start">
      {/*   <button className="light_button m-0 ml-0 mr-3 p-0">
          <Link to={"#"} className="light_button" style={{ fontSize: "1rem" }}>
            See details
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Link>
        </button> */}
        <p className="selection_date mb-0" style={{ fontSize: 18 }}>
              {notif?.data?.name}
            </p>
        <p className="selection_date m-0" style={{ fontSize: "0.7rem" }}>
          {moment(notif.created_at).fromNow()}
        </p>
      </div>
    </div>
  ) : (
    <Link
    to={`/single-post/${notif?.data?.id}`}

    onClick={() => dispatch(handleRightDrawer())}>
    <div
      className="notif_added_prod_container my-2 border p-4"
      style={{
        borderRadius: "8px",
        background: position ? "#e5f1fa" : "white",
        transition: "all 5s ease-in-out",
      }}>
       
      <article className="notif_selection order_changes w-100">
        <div>
         
        {notif.data.category == "moodboars" && (
            <Avatar
              src={moodboars}
              size={"large"}
              style={{
                filter: "brightness(0)",
              }}
              className="order_sent_notif_icon contain"
            />)
          }

      {notif.data.category == "trending" && (
                  <Avatar
                    src={trending}
                    size={"large"}
                    style={{
                      filter: "brightness(0)",
                    }}
                    className="order_sent_notif_icon contain"
                  />)
                }

      {notif.data.category == "3dvideos" && (
                  <Avatar
                    src={video}
                    size={"large"}
                    style={{
                      filter: "brightness(0)",
                    }}
                    className="order_sent_notif_icon contain"
                  />)
                }
        </div>
        <div className="notif_details" style={{ flex: 1 }}>
          {/*         <h2 className="h4_medium mb-1">New creative station posted added {notif.created_at}</h2>
           */}{" "}
          {notif.data.category == "moodboars" && (
            <h2 className="text_notification mb-1">
              Moodboards are the best way to start a new season! Check out ours
              🚀{" "}
            </h2>
          )}
          {notif.data.category == "trending" && (
            <h2 className="text_notification mb-1">
              The hottest fashion gossip is here 💁💁‍♂️{" "}
            </h2>
          )}
          {notif.data.category == "3dvideos" && (
            <h2 className="text_notification mb-1">Check out our newest 3D video 👽</h2>
          )}
             <p className="selection_date mb-0" style={{ fontSize: 18 }}>
              {notif?.data?.name }
            </p>
          <div className="d-flex justify-content-between align-items-center flex-row-reverse">
       
            <p
              className="selection_date mb-0 mt-3"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}>
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    
    </div>
    </Link>
  );
}
