import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { handleRightDrawer } from "../../actions/actionCreators";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Bag from "../../icons/Bag";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const DirectOrderChanged = ({ notif, inPage, position }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  const redirectUser = () => {
    if (notif.data.status === "completed") {
      // user is the owner of the order or collab
      if (user && notif.data.client_id === user.id && !isAdmin) {
        return `/my-orders/${notif.data.id}`;
        // if admin
      } else if (user && isAdmin) {
        return `/all-clients/${notif.data.client.company_id}/1/${notif.data.id}`;
      }
    } else {
      return `/edit-order/${notif.data.id}`;
    }
  };

  return inPage ? (
    <Link to={redirectUser()} className="notif_item_page p-2 my-2">
      <div className="d-flex align-items-center">
        <div className="mx-2 d-flex align-items-center icon_notif_container">
          <Bag />
        </div>
        <h2 className="h4_medium m-0">
          Changes has just been made to {user && !isAdmin && "your order"}
        </h2>
        <p className="notif_name m-0 ml-2" style={{ fontSize: 18 }}>
          {notif.data.name}
        </p>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </Link>
  ) : (
    <div className="border my-2 px-4 py-3" style={{ borderRadius: "8px" }}>
      <Link
        to={redirectUser()}
        className="notif_added_prod_container"
        onClick={() => dispatch(handleRightDrawer())}
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >
        <article className="notif_selection order_changes">
          <Bag />
          <div className="notif_details flex-grow-1">
            <h2 className="h4_medium mb-0">
              Changes has just been made to {user && !isAdmin && "your order"}
            </h2>
            <div>
              <p className="selection_date" style={{ fontSize: 18 }}>
                {notif.data.name}
              </p>
              <p
                className="selection_date mb-0 text-right"
                style={{ fontSize: "0.7rem", fontWeight: 400 }}
              >
                {moment(notif.created_at).fromNow()}
              </p>
            </div>
          </div>
        </article>
      </Link>
    </div>
  );
};
