import { useState } from "react";
import gsap from "gsap";
import image_placeholder from "../../img/img_placeholder.svg";

const LazyImg = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const [imgSource, setImgSource] = useState(
    props.lowQualitySrc || props.thumb
  );

  const imageLoadedHandler = () => {
    setTimeout(() => {
      setImgSource(props.src);
      setLoaded(true);
      gsap.to(".lazy-img", { webkitFilter: "blur(0px)" });
    }, 500); // Reduce delay for faster transition
  };

  return (
    <img
      className={`lazy-img ${props.className}`}
      style={{
        ...props.style,
        filter: !loaded ? "blur(12px)" : "blur(0px)", // More aggressive blur for low-quality image
        transition: "filter 0.5s ease-in-out",
      }}
      onLoad={imageLoadedHandler}
      onError={() => setImgSource(image_placeholder)}
      src={imgSource || image_placeholder}
      alt={props.alt}
      loading="lazy"
    />
  );
};

export default LazyImg;
