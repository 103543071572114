import progress_icon from "../../img/progress_icon.png";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../actions/order-actions/actions";
import { useEffect } from "react";
import { handleRightDrawer } from "../../actions/actionCreators";
import CheckProgress from "../../icons/checkProgress";

interface Props {
  notif: any;
  inPage: boolean;
  position: boolean;
}

export const ProgressPrototypeNotif = ({ notif, inPage, position }: Props) => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state: any) => state.orders);

  useEffect(() => {
    if (!orders.length) {
      dispatch(getOrders("completed"));
    }
  }, [orders]);

  return inPage ? (
    <Link
      to={`/my-orders/${notif.data.order.id}/${notif.data.order_article.id}`}
      className="notif_item_page p-2 my-2"
    >
      <div
        className="d-flex align-items-center notif_selection_check icon_notif_container"
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >
        <CheckProgress />
        {/* <img
          src={CheckProgress}
          alt="progress"
          className="ml-2"
          style={{ height: 30, width: 30 }}
        /> */}
        <h2 className="h4_medium mb-0 mx-2">
          Your prototype is available{" "}
          {notif.data.order_article.status &&
            `(${notif.data.order_article.status.name})`}
        </h2>
        <p className="notif_name m-0 ml-2" style={{ fontSize: 18 }}>
          {notif.data.order_article.name}
        </p>
      </div>
      <p className="selection_date m-0">{moment(notif.created_at).fromNow()}</p>
    </Link>
  ) : (
    <Link
      to={`/my-orders/${notif.data.order.id}/${notif.data.order_article.id}`}
      onClick={() => dispatch(handleRightDrawer())}
      className="notif_added_prod_container"
    >
      <article
        className="notif_selection notif_selection_check my-3"
        style={{
          borderRadius: "8px",
          background: position ? "#e5f1fa" : "white",
          transition: "all 5s ease-in-out",
        }}
      >
        {/* <img
          src={progress_icon}
          alt="progress"
          style={{ height: 30, width: 30, fill: "#00468c", filter: "#00468c" }}
        /> */}
        <CheckProgress />
        <div className="notif_details">
          <h2 className="h4_medium">
            Your prototype is available{" "}
            {notif.data.order_article.status &&
              `(${notif.data.order_article.status.name})`}
          </h2>
          <div>
            <p className="selection_date" style={{ fontSize: 18 }}>
              {notif.data.order_article.name}
            </p>
            <p
              className="selection_date"
              style={{ fontSize: "0.7rem", fontWeight: 400 }}
            >
              {moment(notif.created_at).fromNow()}
            </p>
          </div>
        </div>
      </article>
    </Link>
  );
};
