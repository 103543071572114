import {
  Tooltip,
  Modal,
  Popconfirm,
  Button,
  Tabs,
  Select,
  Skeleton,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
//@ts-ignore
import { fabric } from "fabric";
import { faCheck, faInfo } from "@fortawesome/free-solid-svg-icons";

import "./lazerCanvas.scss";
import { borderRadius } from "react-select/src/theme";
import { HrIcon } from "./icon/HrIcon";
import { BrushIcon } from "./icon/BrushIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { color } from "html2canvas/dist/types/css/types/color";
import { SelectIcon } from "./icon/SelectIcon";
import { RedoIcon } from "./icon/RedoIcon";
import { UndoIcon } from "./icon/UndoIcon";
import { AnnotationIcon } from "./icon/AnnotationIcon";
import { TextIcon } from "./icon/TextIcon";
import { FileIcon } from "./icon/FileIcon";
import { ExposureIcon } from "./icon/ExposureIcon";
import { DeleteIcon } from "./icon/DeleteIcon";
import { ZoomIcon } from "./icon/ZoomIcon";

import axiosInstance from "../../../../config/axios-instance";
import { CopyIcon } from "./icon/CopyIcon";
import { PastIcon } from "./icon/PastIcon";
import { EraseIcon } from "../../icons/EraseIcon";
import { escape } from "querystring";

type Coordinate = {
  x: number;
  y: number;
};

export interface IFrontLazerCanvasProps {
  size: number;
  intensity: number;
  paintLazer: any;
  mousePositionArray: Array<any>;
  handleImageTypes: (image: { file: any; color: string }) => void;
  mouseMoveFrontLazer: (mousePosition: Coordinate) => void;
  initialDrawing: Array<Coordinate>;
  erase: any;
  close: any;
  clearButton: any;
  activeFront: boolean;
}
const CustomizationProduct: React.FC<IFrontLazerCanvasProps> = ({
  size,
  intensity,
  mousePositionArray,
  paintLazer,
  handleImageTypes,
  mouseMoveFrontLazer,
  initialDrawing,
  erase,
  close,
  clearButton,
  activeFront,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef2 = useRef<HTMLCanvasElement>(null);
  const addButtonRefFront = useRef(null);
  const addButtonRefBack = useRef(null);
  const addButtonRefLeft = useRef(null);
  const addButtonRefRight = useRef(null);

  const [can, setCanvas] = useState<any>(null);
  const [canback, setCanvasBack] = useState<any>(null);
  const [canleft, setCanvasLeft] = useState<any>(null);
  const [canright, setCanvasRight] = useState<any>(null);

  const [isPainting, setIsPainting] = useState(false);
  const [textAnnotation, setTextAnnotation] = useState("aaaqs");
  const [clickedImage, setClickedImage] = useState(null);
  console.log("ssss" + activeFront);
  const [activeCanvasFront, setActiveCanvasFront] = useState(true);
  const [activeCanvasBack, setActiveCanvasBack] = useState(false);
  const [activeCanvasLeft, setActiveCanvasLeft] = useState(false);
  const [activeCanvasRight, setActiveCanvasRight] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [activeButtonBack, setActiveButtonBack] = useState(null);
  const [activeButtonLeft, setActiveButtonLeft] = useState(null);
  const [activeButtonRight, setActiveButtonRight] = useState(null);

  const handleClickFont = (buttonIndex: any) => {
    setActiveButton(buttonIndex);
  };

  const handleClickBack = (buttonIndex: any) => {
    setActiveButtonBack(buttonIndex);
  };

  const handleClickLeft = (buttonIndex: any) => {
    setActiveButtonLeft(buttonIndex);
  };
  const handleClickRight = (buttonIndex: any) => {
    setActiveButtonRight(buttonIndex);
  };

  useEffect(() => {
    if (activeFront) {
      setActiveCanvasFront(true);
      setActiveCanvasBack(true);
      setActiveCanvasRight(true);
      setActiveCanvasLeft(true);
    }
  });

  const handleCanvasClickFront = () => {
    setActiveCanvasFront(true);
    setActiveCanvasBack(false);
    setActiveCanvasLeft(false);
    setActiveCanvasRight(false);
  };

  const handleCanvasClickBack = () => {
    setActiveCanvasFront(false);
    setActiveCanvasBack(true);
    setActiveCanvasLeft(false);
    setActiveCanvasRight(false);
  };

  const handleCanvasClickLeft = () => {
    setActiveCanvasFront(false);
    setActiveCanvasBack(false);
    setActiveCanvasLeft(true);
    setActiveCanvasRight(false);
  };

  const handleCanvasClickRight = () => {
    setActiveCanvasFront(false);
    setActiveCanvasBack(false);
    setActiveCanvasLeft(false);
    setActiveCanvasRight(true);
  };
  const [eraser, setEraser] = useState(false);
  const [active, setActive] = useState(false);
  const [valueZoom, setZoom] = useState(1);
  const [valueZoomBack, setZoomBack] = useState(1);
  const [valueZoomLeft, setZoomLeft] = useState(1);
  const [valueZoomRight, setZoomRight] = useState(1);

  const [goPrint, setGoPrint] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openBack, setOpenBack] = React.useState(false);
  const [openLeft, setOpenLeft] = React.useState(false);
  const [openRight, setOpenRight] = React.useState(false);

  const [annotationListFront, setAnnotationListFront] = useState<any>([]);
  const [annotationListBack, setAnnotationListBack] = useState<any>([]);
  const [activeAnnotationFront, setActiveAnnotationFront] = useState(false);
  const [activeAnnotationBack, setActiveAnnotationBack] = useState(false);
  const [activeAnnotationLeft, setActiveAnnotationLeft] = useState(false);
  const [activeAnnotationRight, setActiveAnnotationRight] = useState(false);

  const [owndesignlist, setOwnDesignList] = React.useState<any>([]);
  const [arrayFront, setArrayAnnotation] = React.useState<any>([]);
  const [arrayBack, setArrayAnnotationBack] = React.useState<any>([]);
  const [arrayLeft, setArrayAnnotationLeft] = React.useState<any>([]);
  const [arrayRight, setArrayAnnotationRight] = React.useState<any>([]);

  const showModal = () => {
    setOpen(true);
    fetchLibraries();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const showModalBack = () => {
    setOpenBack(true);
    fetchLibraries();
  };
  const showModalLeft = () => {
    setOpenLeft(true);
    fetchLibraries();
  };

  const showModalRight = () => {
    setOpenRight(true);
    fetchLibraries();
  };

  const handleCancelBack = () => {
    setOpenBack(false);
  };
  const handleCancelLeft = () => {
    setOpenLeft(false);
  };
  const handleCancelRight = () => {
    setOpenRight(false);
  };
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(
    undefined
  );
  const [isLeftClick, setIsLeftClick] = React.useState(true);
  const params = useParams<{ status: string }>();

  const { selectedProduct } = useSelector((state: any) => state.products);

  const [washes, setWashes] = useState<Array<any>>([]);
  const [embellishment, setEmbellishment] = useState<Array<any>>([]);
  const [whiskers, setWhiskers] = useState<Array<any>>([]);

  const [selectedEmbellishment, setSelectedEmbellishment] = useState(null);
  const [selectedWashes, setSelectedWashes] = useState(null);
  const [selectedWhisker, setSelectedWhisker] = useState(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [imageUrls, setimageURls] = useState<Array<any>>([]);
  useEffect(() => {
    // Base URL pattern observed
    // Base URL pattern observed
    // Base URL pattern observed
    const baseUrl =
      "https://360.demcointer.com/ss24/c1c-denim/idmjr24je20s/ov/images/";
    const namePattern = "205";
    const indices = [0, 3, 6, 9];

    indices.forEach((index) => {
      let imageUrl = `${baseUrl}a_0_${index}_${namePattern}_0_0.jpg`;
      imageUrls.push(imageUrl);
    });

    console.log(imageUrls);

    // Check if Chart.js script is already loaded
    if (!document.querySelector("script#chartjs-script")) {
      // Create script element
      const script = document.createElement("script");
      script.id = "erase-script";
      script.src =
        "https://unpkg.com/fabric@4.5.1/src/mixins/eraser_brush.mixin.js";
      script.onload = () => setChartLoaded(true); // Set flag when script is loaded

      // Append the script to the document body
      document.body.appendChild(script);
      console.log(chartLoaded);
      // Remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    } else {
      // If the script is already in the document, set the flag to true
      setChartLoaded(true);
    }
  }, []);
  const onChange = (key: any) => {
    console.log("aaaa" + key);
  };

  const handleChangeEmbellishment = (event: any) => {
    setSelectedEmbellishment(event);
  };

  const handleChangeWashes = (event: any) => {
    setSelectedWashes(event);
  };
  const handleChangeWhisker = (event: any) => {
    setSelectedWhisker(event);
  };

  const fetchLibraries = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/customized_products/getLibraries`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      setEmbellishment(res.data.embllishment_libraries);
      setWashes(res.data.washes_libraries);
      setWhiskers(res.data.whisker_libraries);
    });
  };

  useEffect(() => {
    fetchLibraries();
    //@ts-ignore
    //  var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-font");

    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvasFront();

    function resizeCanvasFront() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvas(canvas);
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt: any) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level
      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoom(zoom);
    });

    /*   function init() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct.front_image,

        function (imgFront) {

          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            imgFront.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            imgFront.scaleToWidth(520);
          }

          imgFront.clone((img: any) => {
            canvas.renderAll();
          });

          const canvasCenterX = canvas.getWidth() / 2;
          const canvasCenterY = canvas.getHeight() / 2;
          const imgCenterX = imgFront.getScaledWidth() / 2;
          const imgCenterY = imgFront.getScaledHeight() / 2;
          //@ts-ignore
          canvas.setBackgroundImage(imgFront);
          //@ts-ignore
          imgFront.set({
            //@ts-ignore
            erasable: false,
            left: canvasCenterX - imgCenterX,
            top: canvasCenterY - imgCenterY,
          });

          const filtersFront = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          imgFront.filters.push(filtersFront.brightness);
          const brightnessInput = document.querySelector("#brightness-front");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);

            // Edit the filter value
            //@ts-ignore
            filtersFront.brightness.brightness = value;
            // Apply the changes
            imgFront.applyFilters();
            // Display the result
            canvas.renderAll();
          };
          //  canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } */

    function init() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct.front_image,
        function (imgFront: any) {
          const tempCanvas = document.createElement("canvas");
          const tempCtx = tempCanvas.getContext("2d");
          //@ts-ignore
          tempCanvas.width = imgFront.width;
          //@ts-ignore
          tempCanvas.height = imgFront.height;
          //@ts-ignore
          tempCtx.drawImage(imgFront.getElement(), 0, 0);
          //@ts-ignore
          const imageData = tempCtx.getImageData(
            0,
            0,
            tempCanvas.width,
            tempCanvas.height
          );
          const data = imageData.data;

          // Simple white background removal (thresholding)
          const threshold = 250; // Adjust the threshold as needed
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            if (r > threshold && g > threshold && b > threshold) {
              data[i + 3] = 0; // Set alpha to 0 (transparent)
            }
          }
          //@ts-ignore
          tempCtx.putImageData(imageData, 0, 0);
          const processedImageUrl = tempCanvas.toDataURL();

          fabric.Image.fromURL(
            processedImageUrl,
            function (processedImgFront: any) {
              // Scale the image based on the window width
              if (window.innerWidth < 1372) {
                processedImgFront.scaleToWidth(300);
              } else {
                processedImgFront.scaleToWidth(520);
              }

              // Calculate canvas and image centers
              const canvasCenterX = canvas.getWidth() / 2;
              const canvasCenterY = canvas.getHeight() / 2;
              const imgCenterX = processedImgFront.getScaledWidth() / 2;
              const imgCenterY = processedImgFront.getScaledHeight() / 2;

              // Set image properties
              processedImgFront.set({
                //@ts-ignore
                erasable: false,
                left: canvasCenterX - imgCenterX,
                top: canvasCenterY - imgCenterY,
              });

              // Add the image to the canvas
              //  canvas.add(processedImgRight);
              canvas.renderAll();

              // Define and apply brightness filter
              const brightnessFilter = new fabric.Image.filters.Brightness();
              //@ts-ignore
              processedImgFront.filters.push(brightnessFilter);

              // Listen to brightness input changes
              const brightnessInput =
                document.querySelector("#brightness-front");
              //@ts-ignore
              brightnessInput.oninput = () => {
                //@ts-ignore
                const value = parseFloat(brightnessInput.value);
                //@ts-ignore
                brightnessFilter.brightness = value;
                processedImgFront.applyFilters();
                canvas.renderAll();
              };

              // Set the image as the background
              canvas.setBackgroundImage(
                processedImgFront,
                canvas.renderAll.bind(canvas),
                {
                  crossOrigin: "anonymous",
                }
              );
            },
            { crossOrigin: "anonymous" }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }
    if (params.status != "edit") {
      init();
    }
    // Function to copy and paste the selected object

    /*  const copyAndPasteButton = document.querySelector("#copyAndPasteButton");
    //@ts-ignore
    copyAndPasteButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Array to store the cloned objects
      //@ts-ignore
      const clonedObjects = [];

      // Clone the selected objects and add them to the canvas
      activeObjects.forEach((obj) => {
        const clonedObject = fabric.util.object.clone(obj);

        // Increment the position slightly to visually differentiate the pasted objects
        clonedObject.set({
          left: clonedObject.left + 20,
          top: clonedObject.top + 20,
        });

        canvas.add(clonedObject);
        clonedObjects.push(clonedObject);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(clonedObjects, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    }); */
    //@ts-ignore
    let copiedObjectsFront = [];

    // Function to copy the selected objects
    const copyButtonFront = document.querySelector("#copyButtonFront");
    //@ts-ignore
    copyButtonFront.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsFront = [];

      // Clone the selected objects and store them in the copiedObjectsFront array
      activeObjects.forEach((obj: any) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsFront.push(clonedObject);
      });
    });

    // Function to paste the copied objects
    const pasteButtonFront = document.querySelector("#pasteButtonFront");
    //@ts-ignore
    pasteButtonFront.addEventListener("click", function () {
      if (copiedObjectsFront.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsFront.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsFront, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    });

    const selectToolButton = document.querySelector("#select-tool-front");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-front");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      var brush = new fabric.PencilBrush(canvas);
      var opacityValue = 0.5;

      //@ts-ignore

      var colorInput = document.getElementById("color-picker-front").value;
      //   brush.color = colorInput;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-front").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-front").value;
      const value = parseFloat(widthInput);

      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);
      brush.color = brushColor.toRgba();
      //  brush.width = widthInput;
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      //  //saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("add-comment-front")
      .addEventListener("click", function () {
        //@ts-ignore
        var colorInput = document.getElementById(
          "color-picker-front-text"
          //@ts-ignore
        ).value;

        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          fill: colorInput,
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });
    //@ts-ignore
    document
      .getElementById("eraser-tool-front")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-front").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        // //saveCanvasData();
      });

    const removeToolButton = document.querySelector("#remove-object-front");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      //  //saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.remove(activeObject);
          //@ts-ignore
          console.log(Number(activeObject.text));
          //@ts-ignore
          //  array.splice(Number(activeObject.text), 1);
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayFront.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayFront.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotation([...arrayFront]);
          localStorage.setItem(
            "arrayAnnotationFront" + selectedProduct.code,
            arrayFront
          );
        }
      }

      //  //saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document
      .getElementById("undo-front")
      .addEventListener("click", function () {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
        //  //saveCanvasData();
      });
    //@ts-ignore
    document
      .getElementById("redo-front")
      .addEventListener("click", function () {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
        //   //saveCanvasData();
      });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event: any) {
      // Get the selected object
      var selectedObject = event.target;
      console.log("sssss");

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-front");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    /*   function //saveCanvasData() {
      var canvasData = JSON.stringify(canvas.toJSON());
      console.log(canvasData);
      localStorage.setItem(
        "canvasDataFront" + selectedProduct.code,
        canvasData
      );
    } */

    /*    function loadCanvasData() {
      var canvasData = localStorage.getItem(
        "canvasDataFront" + selectedProduct.code
      );
      if (canvasData) {
        canvas.loadFromJSON(canvasData, function () {
          canvas.renderAll();
        });
      }
    } */

    const handleButtonClickFront = () => {
      setActiveAnnotationFront(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-front").value;

      //@ts-ignore
      if (text) {
        //@ts-ignore
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        //  var group = new fabric.Group([pin]);
        /*     setAnnotationListFront((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white",padding:"2px" }}>
              {`${annotation}`}
            </span>
            : <span style={{fontSize:"20px"}}>{` ${textObject.text}`}</span>
          </div>,
        ]); */
        arrayFront.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotation(arrayFront);
        localStorage.setItem(
          "arrayAnnotationFront" + selectedProduct.code,
          arrayFront
        );
        annotation++;
        //  //saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-front").value = "";
        setActiveAnnotationFront(false);
      }
    };
    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-front");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-front");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const EraserSizeInput = document.getElementById("eraser-size-front");
    //@ts-ignore
    EraserSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const brushOpacityInput = document.getElementById("brush-opacity-front");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefFront.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickFront);
    }
    window.addEventListener("resize", resizeCanvasFront);

    return () => {
      window.removeEventListener("resize", resizeCanvasFront);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickFront);
      }
    };
  }, []);

  /* const handleImageClick = (imageUrl: any) => {
    if (can) {
      var src = imageUrl;
      fabric.Image.fromURL(src, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
         can.add(img);
        can.setActiveObject(img);
        can.renderAll();

      });
      //saveCanvasData();
    }
  }; */

  const handleImageClick = (imageUrl: any) => {
    if (can) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          can.add(img);
          can.setActiveObject(img);
          can.renderAll();
          //  //saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };

  const uploadFile = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];

    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        can.add(img);
        can.setActiveObject(img);
        can.renderAll();
      });
    };
    reader.readAsDataURL(file);

    // //saveCanvasData();
    handleImageTypes({ file: e.target.files[0], color: generate_color });

    handleCancel();
  };
  const handleImageClickBack = (imageUrl: any) => {
    if (canback) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          canback.add(img);
          canback.setActiveObject(img);
          canback.renderAll();
          //   //saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };
  const uploadFileBack = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];

    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        canback.add(img);
        canback.setActiveObject(img);
        canback.renderAll();
      });
    };
    reader.readAsDataURL(file);

    //  //saveCanvasData();
    handleImageTypes({ file: e.target.files[0], color: generate_color });

    handleCancel();
  };

  /*   function //saveCanvasData() {
    if (can) {
      var canvasData = JSON.stringify(can.toJSON());
      localStorage.setItem(
        "canvasDataFront" + selectedProduct.code,
        canvasData
      );
    }
  } */

  useEffect(() => {
    //@ts-ignore
    var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-back");
    /*     canvas.setWidth(500);
    canvas.setHeight(600); */
    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvas();

    function resizeCanvas() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvasBack(canvas);
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt: any) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level

      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoomBack(zoom);
    });
    /* function initBack() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct.back_image,

        function (img) {
          // img.set("erasable", false);
          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            img.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            img.scaleToWidth(520);
          }
     img.clone((img: any) => {
            canvas.renderAll();
          });
          const canvasCenterX = canvas.getWidth() / 2;
          const canvasCenterY = canvas.getHeight() / 2;
          const imgCenterX = img.getScaledWidth() / 2;
          const imgCenterY = img.getScaledHeight() / 2;
          //@ts-ignore
          canvas.setBackgroundImage(img);
          //@ts-ignore
          img.set({
            //@ts-ignore
            erasable: false,
            left: canvasCenterX - imgCenterX,
            top: canvasCenterY - imgCenterY,
          });

          const filters = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          img.filters.push(filters.brightness);
          const brightnessInput = document.querySelector("#brightness-back");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);
            // Edit the filter value
            //@ts-ignore
            filters.brightness.brightness = value;
            // Apply the changes
            img.applyFilters();
            // Display the result
            canvas.renderAll();
          };
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } */
    function initBack() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct.back_image,
        function (imgBack: any) {
          const tempCanvas = document.createElement("canvas");
          const tempCtx = tempCanvas.getContext("2d");
          //@ts-ignore
          tempCanvas.width = imgBack.width;
          //@ts-ignore
          tempCanvas.height = imgBack.height;
          //@ts-ignore
          tempCtx.drawImage(imgBack.getElement(), 0, 0);
          //@ts-ignore
          const imageData = tempCtx.getImageData(
            0,
            0,
            tempCanvas.width,
            tempCanvas.height
          );
          const data = imageData.data;

          // Simple white background removal (thresholding)
          const threshold = 250; // Adjust the threshold as needed
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            if (r > threshold && g > threshold && b > threshold) {
              data[i + 3] = 0; // Set alpha to 0 (transparent)
            }
          }
          //@ts-ignore
          tempCtx.putImageData(imageData, 0, 0);
          const processedImageUrl = tempCanvas.toDataURL();

          fabric.Image.fromURL(
            processedImageUrl,
            function (processedImgBack: any) {
              // Scale the image based on the window width
              if (window.innerWidth < 1372) {
                processedImgBack.scaleToWidth(300);
              } else {
                processedImgBack.scaleToWidth(520);
              }

              // Calculate canvas and image centers
              const canvasCenterX = canvas.getWidth() / 2;
              const canvasCenterY = canvas.getHeight() / 2;
              const imgCenterX = processedImgBack.getScaledWidth() / 2;
              const imgCenterY = processedImgBack.getScaledHeight() / 2;

              // Set image properties
              processedImgBack.set({
                //@ts-ignore
                erasable: false,
                left: canvasCenterX - imgCenterX,
                top: canvasCenterY - imgCenterY,
              });

              // Add the image to the canvas
              //  canvas.add(processedImgRight);
              canvas.renderAll();

              // Define and apply brightness filter
              const brightnessFilter = new fabric.Image.filters.Brightness();
              //@ts-ignore
              processedImgBack.filters.push(brightnessFilter);

              // Listen to brightness input changes
              const brightnessInput =
                document.querySelector("#brightness-back");
              //@ts-ignore
              brightnessInput.oninput = () => {
                //@ts-ignore
                const value = parseFloat(brightnessInput.value);
                //@ts-ignore
                brightnessFilter.brightness = value;
                processedImgBack.applyFilters();
                canvas.renderAll();
              };

              // Set the image as the background
              canvas.setBackgroundImage(
                processedImgBack,
                canvas.renderAll.bind(canvas),
                {
                  crossOrigin: "anonymous",
                }
              );
            },
            { crossOrigin: "anonymous" }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }
    if (params.status != "edit") {
      initBack();
    } else {
      let jsonString = JSON.parse(selectedProduct?.tmp_canvas_back);

      // Remove double quotes at the beginning and end
      if (
        jsonString?.charAt(0) === '"' &&
        jsonString?.charAt(jsonString?.length - 1) === '"'
      ) {
        jsonString = jsonString?.substring(1, jsonString?.length - 1);
      }
      jsonString = jsonString?.replace(/\\/g, "");
      if (jsonString) {
        canvas.loadFromJSON(jsonString, function () {
          canvas.renderAll();
        });
      }
    }

    const selectToolButton = document.querySelector("#select-tool-back");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-back");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      // Déplacez cette partie du code à l'intérieur de l'événement "click"
      //@ts-ignore
      var colorInput = document.getElementById("color-picker-back").value;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-back").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-back").value;
      const value = parseFloat(widthInput);
      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);

      var brush = new fabric.PencilBrush(canvas);
      brush.color = brushColor.toRgba();
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      //   //saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("eraser-tool-back")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-back").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        //    //saveCanvasData();
      });

    //@ts-ignore
    var colorInput = document.getElementById("color-picker-back-text").value;

    //@ts-ignore
    document
      .getElementById("add-comment-back")
      .addEventListener("click", function () {
        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          fill: colorInput,
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });

    //@ts-ignore
    let copiedObjectsBack = [];

    // Function to copy the selected objects
    const copyButtonBack = document.querySelector("#copyButtonBack");
    //@ts-ignore
    copyButtonBack.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsBack = [];

      // Clone the selected objects and store them in the copiedObjectsBack array
      activeObjects.forEach((obj: any) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsBack.push(clonedObject);
      });
    });

    // Function to paste the copied objects
    const pasteButtonBack = document.querySelector("#pasteButtonBack");
    //@ts-ignore
    pasteButtonBack.addEventListener("click", function () {
      if (copiedObjectsBack.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsBack.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsBack, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    });

    const removeToolButton = document.querySelector("#remove-object-back");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      ////saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();

        if (activeObject) {
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayBack.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayBack.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotationBack([...arrayBack]);
          localStorage.setItem(
            "arrayAnnotationBack" + selectedProduct.code,
            arrayBack
          );

          canvas.remove(activeObject);
          canvas.renderAll();
        }
      }

      //  //saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document.getElementById("undo-back").addEventListener("click", function () {
      if (canvas._objects.length > 0) {
        h.push(canvas._objects.pop());
        canvas.renderAll();
      }
      //   //saveCanvasData();
    });
    //@ts-ignore
    document.getElementById("redo-back").addEventListener("click", function () {
      if (h.length > 0) {
        isRedoing = true;
        //@ts-ignore
        canvas.add(h.pop());
      }
      ////saveCanvasData();
    });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event: any) {
      // Get the selected object
      var selectedObject = event.target;

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-back");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    //@ts-ignore
    /* document
      .getElementById("add-text-back")
      .addEventListener("click", function () {
        console.log("ssss");
        canvas.isDrawingMode = false;
        //@ts-ignore
        var text = document.getElementById("text-input-back").value;
        //@ts-ignore
        var colorInput = document.getElementById("color-picker-back").value;

        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        setAnnotationListBack((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white" }}>
              {`${annotation}`}
            </span>
            : {` ${textObject.text}`}
          </div>,
        ]);

        array.push(textObject.text);
        canvas.add(pin);

        annotation++;
        //saveCanvasData();
      }); */
    const handleButtonClickBack = () => {
      setActiveAnnotationBack(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-back").value;
      if (text) {
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        /*  //  var group = new fabric.Group([pin]);
        setAnnotationListBack((prevList: any) => [
          ...prevList,
          <div key={prevList.length}>
            <span style={{ backgroundColor: "#9A9B46", color: "white",padding:"2px" }}>
              {`${annotation}`}
            </span>
            : <span style={{fontSize:"20px"}}>{` ${textObject.text}`}</span>
          </div>,
        ]); */

        //array.push(textObject.text);

        arrayBack.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotationBack(arrayBack);
        localStorage.setItem(
          "arrayAnnotationBack" + selectedProduct.code,
          arrayBack
        );
        annotation++;
        //  //saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-back").value = "";
        setActiveAnnotationBack(false);
      }
    };

    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-back");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-back");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushOpacityInput = document.getElementById("brush-opacity-back");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefBack.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickBack);
    }
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickBack);
      }
    };
  }, []);

  useEffect(() => {
    //@ts-ignore
    var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-left");
    /*     canvas.setWidth(500);
    canvas.setHeight(600); */
    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvas();

    function resizeCanvas() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvasLeft(canvas);
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt: any) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level

      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoomLeft(zoom);
    });
    /*  function initLeft() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct?.images[2].path,

        function (imgLeft) {
          // img.set("erasable", false);
          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            imgLeft.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            imgLeft.scaleToWidth(520);
          }
        imgLeft.clone((img: any) => {
            canvas.renderAll();
          });
          const canvasCenterX = canvas.getWidth() / 2;
          const canvasCenterY = canvas.getHeight() / 2;
          const imgCenterX = imgLeft.getScaledWidth() / 2;
          const imgCenterY = imgLeft.getScaledHeight() / 2;
          //@ts-ignore
          canvas.setBackgroundImage(imgLeft);
          //@ts-ignore
          imgLeft.set({
            //@ts-ignore
            erasable: false,
            left: canvasCenterX - imgCenterX,
            top: canvasCenterY - imgCenterY,
          });

          const filters = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          imgLeft.filters.push(filters.brightness);
          const brightnessInput = document.querySelector("#brightness-left");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);
            // Edit the filter value
            //@ts-ignore
            filters.brightness.brightness = value;
            // Apply the changes
            imgLeft.applyFilters();
            // Display the result
            canvas.renderAll();
          };
          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } */

    function initLeft() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct?.images[2].path,
        function (imgLeft: any) {
          const tempCanvas = document.createElement("canvas");
          const tempCtx = tempCanvas.getContext("2d");
          //@ts-ignore
          tempCanvas.width = imgLeft.width;
          //@ts-ignore
          tempCanvas.height = imgLeft.height;
          //@ts-ignore
          tempCtx.drawImage(imgLeft.getElement(), 0, 0);
          //@ts-ignore
          const imageData = tempCtx.getImageData(
            0,
            0,
            tempCanvas.width,
            tempCanvas.height
          );
          const data = imageData.data;

          // Simple white background removal (thresholding)
          const threshold = 250; // Adjust the threshold as needed
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            if (r > threshold && g > threshold && b > threshold) {
              data[i + 3] = 0; // Set alpha to 0 (transparent)
            }
          }
          //@ts-ignore
          tempCtx.putImageData(imageData, 0, 0);
          const processedImageUrl = tempCanvas.toDataURL();

          fabric.Image.fromURL(
            processedImageUrl,
            function (processedImgLeft: any) {
              // Scale the image based on the window width
              if (window.innerWidth < 1372) {
                processedImgLeft.scaleToWidth(300);
              } else {
                processedImgLeft.scaleToWidth(520);
              }

              // Calculate canvas and image centers
              const canvasCenterX = canvas.getWidth() / 2;
              const canvasCenterY = canvas.getHeight() / 2;
              const imgCenterX = processedImgLeft.getScaledWidth() / 2;
              const imgCenterY = processedImgLeft.getScaledHeight() / 2;

              // Set image properties
              processedImgLeft.set({
                //@ts-ignore
                erasable: false,
                left: canvasCenterX - imgCenterX,
                top: canvasCenterY - imgCenterY,
              });

              // Add the image to the canvas
              //  canvas.add(processedImgRight);
              canvas.renderAll();

              // Define and apply brightness filter
              const brightnessFilter = new fabric.Image.filters.Brightness();
              //@ts-ignore
              processedImgLeft.filters.push(brightnessFilter);

              // Listen to brightness input changes
              const brightnessInput =
                document.querySelector("#brightness-left");
              //@ts-ignore
              brightnessInput.oninput = () => {
                //@ts-ignore
                const value = parseFloat(brightnessInput.value);
                //@ts-ignore
                brightnessFilter.brightness = value;
                processedImgLeft.applyFilters();
                canvas.renderAll();
              };

              // Set the image as the background
              canvas.setBackgroundImage(
                processedImgLeft,
                canvas.renderAll.bind(canvas),
                {
                  crossOrigin: "anonymous",
                }
              );
            },
            { crossOrigin: "anonymous" }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }
    if (params.status != "edit") {
      initLeft();
    } else {
      let jsonString = JSON.parse(selectedProduct?.tmp_canvas_left);

      // Remove double quotes at the beginning and end
      if (
        jsonString?.charAt(0) === '"' &&
        jsonString?.charAt(jsonString?.length - 1) === '"'
      ) {
        jsonString = jsonString?.substring(1, jsonString?.length - 1);
      }
      jsonString = jsonString?.replace(/\\/g, "");
      if (jsonString) {
        canvas.loadFromJSON(jsonString, function () {
          canvas.renderAll();
        });
      }
    }

    const selectToolButton = document.querySelector("#select-tool-left");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-left");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      // Déplacez cette partie du code à l'intérieur de l'événement "click"
      //@ts-ignore
      var colorInput = document.getElementById("color-picker-left").value;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-left").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-left").value;
      const value = parseFloat(widthInput);
      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);

      var brush = new fabric.PencilBrush(canvas);
      brush.color = brushColor.toRgba();
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      //saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("eraser-tool-left")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-left").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        //saveCanvasData();
      });

    const colorPickerTextLeft = document.getElementById(
      "color-picker-left-text"
    );

    //@ts-ignore
    document
      .getElementById("add-comment-left")
      .addEventListener("click", function () {
        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          //@ts-ignore
          fill: colorPickerTextLeft,
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });

    //@ts-ignore
    let copiedObjectsLeft = [];

    // Function to copy the selected objects
    const copyButtonLeft = document.querySelector("#copyButtonLeft");
    //@ts-ignore
    copyButtonLeft.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsLeft = [];

      // Clone the selected objects and store them in the copiedObjectsBack array
      activeObjects.forEach((obj: any) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsLeft.push(clonedObject);
      });
    });

    // Function to paste the copied objects
    const pasteButtonLeft = document.querySelector("#pasteButtonLeft");
    //@ts-ignore
    pasteButtonLeft.addEventListener("click", function () {
      if (copiedObjectsLeft.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsLeft.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsLeft, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    });

    const removeToolButton = document.querySelector("#remove-object-left");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      //saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();

        if (activeObject) {
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayLeft.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayLeft.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotationLeft([...arrayLeft]);
          localStorage.setItem(
            "arrayAnnotationLeft" + selectedProduct.code,
            arrayLeft
          );

          canvas.remove(activeObject);
          canvas.renderAll();
        }
      }

      //saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document.getElementById("undo-left").addEventListener("click", function () {
      if (canvas._objects.length > 0) {
        h.push(canvas._objects.pop());
        canvas.renderAll();
      }
      //saveCanvasData();
    });
    //@ts-ignore
    document.getElementById("redo-left").addEventListener("click", function () {
      if (h.length > 0) {
        isRedoing = true;
        //@ts-ignore
        canvas.add(h.pop());
      }
      //saveCanvasData();
    });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event: any) {
      // Get the selected object
      var selectedObject = event.target;

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-left");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    const handleButtonClickLeft = () => {
      setActiveAnnotationLeft(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-left").value;
      if (text) {
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        arrayLeft.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotationBack(arrayLeft);
        localStorage.setItem(
          "arrayAnnotationLeft" + selectedProduct.code,
          arrayLeft
        );
        annotation++;
        //saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-left").value = "";
        setActiveAnnotationLeft(false);
      }
    };

    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-left");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-left");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushOpacityInput = document.getElementById("brush-opacity-left");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefLeft.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickLeft);
    }
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickLeft);
      }
    };
  }, []);

  const handleImageClickLeft = (imageUrl: any) => {
    if (canleft) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          canleft.add(img);
          canleft.setActiveObject(img);
          canleft.renderAll();
          //saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };

  const uploadFileLeft = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        canleft.add(img);
        canleft.setActiveObject(img);
        canleft.renderAll();
      });
    };
    reader.readAsDataURL(file);
    handleImageTypes({ file: e.target.files[0], color: generate_color });

    //saveCanvasData();
    handleCancelLeft();
  };

  useEffect(() => {
    //@ts-ignore
    var array = [];
    var annotation = 1;
    const canvas = new fabric.Canvas("canvas-right");
    /*     canvas.setWidth(500);
    canvas.setHeight(600); */
    canvas.setWidth(window.innerWidth < 1025 ? 300 : 500);
    canvas.setHeight(window.innerWidth < 1025 ? 300 : 600);
    resizeCanvas();

    function resizeCanvas() {
      const container = document.querySelector(".container.active-border");
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        canvas.renderAll();
      }
    }

    setCanvasRight(canvas);
    canvas.setZoom(1); // set initial zoom level

    canvas.on("mouse:wheel", function (opt: any) {
      canvas.selection = false;
      var delta = opt.e.deltaY; // get the amount of change from the mouse wheel
      var zoom = canvas.getZoom(); // get the current zoom level
      zoom *= 0.999 ** delta; // apply the change to the zoom level

      if (zoom > 20) zoom = 20; // set a maximum zoom level
      if (zoom < 1) zoom = 1; // set a minimum zoom level
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom); // zoom to the mouse cursor
      opt.e.preventDefault(); // prevent the default scrolling behavior
      setZoomRight(zoom);
    });
    /*  function initRight() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct?.images[3].path,

        function (imgRight) {
          // img.set("erasable", false);
          if (window.innerWidth < 1372) {
            console.log(window.innerWidth);
            imgRight.scaleToWidth(300);
          } else {
            console.log(window.innerWidth);
            imgRight.scaleToWidth(520);
          }
          imgRight.clone((img: any) => {
            canvas.renderAll();
          });
          const canvasCenterX = canvas.getWidth() / 2;
          const canvasCenterY = canvas.getHeight() / 2;
          const imgCenterX = imgRight.getScaledWidth() / 2;
          const imgCenterY = imgRight.getScaledHeight() / 2;
          //@ts-ignore
          canvas.setBackgroundImage(imgRight);
          //@ts-ignore
          imgRight.set({
            //@ts-ignore
            erasable: false,
            left: canvasCenterX - imgCenterX,
            top: canvasCenterY - imgCenterY,
          });

          const filters = {
            brightness: new fabric.Image.filters.Brightness(),
          };

          // - Brightness
          // Attach the filter to the image
          //@ts-ignore
          imgRight.filters.push(filters.brightness);
          const brightnessInput = document.querySelector("#brightness-right");
          //@ts-ignore
          brightnessInput.oninput = () => {
            //@ts-ignore
            const value = parseFloat(brightnessInput.value);
            // Edit the filter value
            //@ts-ignore
            filters.brightness.brightness = value;
            // Apply the changes
            imgRight.applyFilters();

            // Display the result
            canvas.renderAll();
          };
          canvas.add(imgRight);
          canvas.setBackgroundImage(imgRight, canvas.renderAll.bind(canvas), {
            crossOrigin: "anonymous",
          });

          canvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    } */
    function initRight() {
      fabric.Image.fromURL(
        process.env.REACT_APP_STORAGE_URL + selectedProduct?.images[3].path,
        function (imgRight: any) {
          const tempCanvas = document.createElement("canvas");
          const tempCtx = tempCanvas.getContext("2d");
          //@ts-ignore
          tempCanvas.width = imgRight.width;
          //@ts-ignore
          tempCanvas.height = imgRight.height;
          //@ts-ignore
          tempCtx.drawImage(imgRight.getElement(), 0, 0);
          //@ts-ignore
          const imageData = tempCtx.getImageData(
            0,
            0,
            tempCanvas.width,
            tempCanvas.height
          );
          const data = imageData.data;

          // Simple white background removal (thresholding)
          const threshold = 250; // Adjust the threshold as needed
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            if (r > threshold && g > threshold && b > threshold) {
              data[i + 3] = 0; // Set alpha to 0 (transparent)
            }
          }
          //@ts-ignore
          tempCtx.putImageData(imageData, 0, 0);
          const processedImageUrl = tempCanvas.toDataURL();

          fabric.Image.fromURL(
            processedImageUrl,
            function (processedImgRight: any) {
              // Scale the image based on the window width
              if (window.innerWidth < 1372) {
                processedImgRight.scaleToWidth(300);
              } else {
                processedImgRight.scaleToWidth(520);
              }

              // Calculate canvas and image centers
              const canvasCenterX = canvas.getWidth() / 2;
              const canvasCenterY = canvas.getHeight() / 2;
              const imgCenterX = processedImgRight.getScaledWidth() / 2;
              const imgCenterY = processedImgRight.getScaledHeight() / 2;

              // Set image properties
              processedImgRight.set({
                //@ts-ignore
                erasable: false,
                left: canvasCenterX - imgCenterX,
                top: canvasCenterY - imgCenterY,
              });

              // Add the image to the canvas
              //  canvas.add(processedImgRight);
              canvas.renderAll();

              // Define and apply brightness filter
              const brightnessFilter = new fabric.Image.filters.Brightness();
              //@ts-ignore
              processedImgRight.filters.push(brightnessFilter);

              // Listen to brightness input changes
              const brightnessInput =
                document.querySelector("#brightness-right");
              //@ts-ignore
              brightnessInput.oninput = () => {
                //@ts-ignore
                const value = parseFloat(brightnessInput.value);
                //@ts-ignore
                brightnessFilter.brightness = value;
                processedImgRight.applyFilters();
                canvas.renderAll();
              };

              // Set the image as the background
              canvas.setBackgroundImage(
                processedImgRight,
                canvas.renderAll.bind(canvas),
                {
                  crossOrigin: "anonymous",
                }
              );
            },
            { crossOrigin: "anonymous" }
          );
        },
        { crossOrigin: "anonymous" }
      );
    }

    if (params.status != "edit") {
      initRight();
    } else {
      let jsonString = JSON.parse(selectedProduct?.tmp_canvas_right);

      // Remove double quotes at the beginning and end
      if (
        jsonString?.charAt(0) === '"' &&
        jsonString?.charAt(jsonString?.length - 1) === '"'
      ) {
        jsonString = jsonString?.substring(1, jsonString?.length - 1);
      }
      jsonString = jsonString?.replace(/\\/g, "");
      if (jsonString) {
        canvas.loadFromJSON(jsonString, function () {
          canvas.renderAll();
        });
      }
    }

    const selectToolButton = document.querySelector("#select-tool-right");
    //@ts-ignore
    selectToolButton.addEventListener("click", () => {
      canvas.isDrawingMode = false;
    });

    const brushToolButton = document.querySelector("#brush-tool-right");
    //@ts-ignore
    brushToolButton.addEventListener("click", function () {
      // Déplacez cette partie du code à l'intérieur de l'événement "click"
      //@ts-ignore
      var colorInput = document.getElementById("color-picker-right").value;
      //@ts-ignore
      var widthInput = document.getElementById("brush-size-right").value;
      //@ts-ignore
      var opacityInput = document.getElementById("brush-opacity-right").value;
      const value = parseFloat(widthInput);
      const valueOpacity = parseFloat(opacityInput);
      var brushColor = new fabric.Color(colorInput);
      brushColor.setAlpha(valueOpacity);

      var brush = new fabric.PencilBrush(canvas);
      brush.color = brushColor.toRgba();
      brush.width = value;

      canvas.freeDrawingBrush = brush;
      canvas.isDrawingMode = true;
      //saveCanvasData();
    });

    //@ts-ignore
    document
      .getElementById("eraser-tool-right")
      .addEventListener("click", function () {
        //@ts-ignore
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        //@ts-ignore
        var widthInput = document.getElementById("eraser-size-right").value;

        canvas.freeDrawingBrush.width = widthInput;
        canvas.isDrawingMode = true;

        //saveCanvasData();
      });
    const colorPickerTextRight = document.getElementById(
      "color-picker-right-text"
    );

    //@ts-ignore
    document
      .getElementById("add-comment-right")
      .addEventListener("click", function () {
        var input = new fabric.IText("Enter text", {
          left: 100,
          top: 100,
          fontFamily: "Arial",
          fontSize: 20,
          //@ts-ignore
          fill: colorPickerTextRight,
        });

        canvas.add(input);

        input.enterEditing();

        document.addEventListener("keydown", function (event) {
          if (event.code === "Escape") {
            canvas.remove(input);
          }
        });
      });

    //@ts-ignore
    let copiedObjectsRight = [];

    // Function to copy the selected objects
    const copyButtonRight = document.querySelector("#copyButtonRight");
    //@ts-ignore
    copyButtonRight.addEventListener("click", function () {
      const activeObjects = canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        alert("Please select objects first.");
        return;
      }

      // Clear the previous copied objects
      copiedObjectsRight = [];

      // Clone the selected objects and store them in the copiedObjectsBack array
      activeObjects.forEach((obj: any) => {
        const clonedObject = fabric.util.object.clone(obj);
        copiedObjectsRight.push(clonedObject);
      });
    });

    // Function to paste the copied objects
    const pasteButtonRight = document.querySelector("#pasteButtonRight");
    //@ts-ignore
    pasteButtonRight.addEventListener("click", function () {
      if (copiedObjectsRight.length === 0) {
        alert("No objects copied.");
        return;
      }

      // Paste the copied objects onto the canvas
      //@ts-ignore
      copiedObjectsRight.forEach((obj) => {
        // Increment the position slightly to visually differentiate the pasted objects
        obj.set({
          left: 20,
          top: 20,
        });

        canvas.add(obj);
      });

      // Deselect all objects
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Make the cloned objects active (selected)
      canvas.setActiveObject(
        //@ts-ignore
        new fabric.ActiveSelection(copiedObjectsRight, {
          canvas: canvas,
        })
      );

      // Render the canvas to see the pasted objects
      canvas.renderAll();
    });

    const removeToolButton = document.querySelector("#remove-object-right");
    //@ts-ignore
    removeToolButton.addEventListener("click", function () {
      canvas.isDrawingMode = false;

      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.renderAll();
      }
      //saveCanvasData();
    });
    document.addEventListener("keydown", function (event) {
      if (event.code === "Delete" || event.code === "Backspace") {
        var activeObject = canvas.getActiveObject();

        if (activeObject) {
          //@ts-ignore
          const idToRemove = Number(activeObject.text);

          // Find the index of the item with the given ID
          const index = arrayRight.findIndex(
            (item: any) => item.id === idToRemove
          );

          // Remove the item if it exists in the array
          if (index !== -1) {
            arrayRight.splice(index, 1);
          }
          canvas.renderAll();
          setArrayAnnotationLeft([...arrayRight]);
          localStorage.setItem(
            "arrayAnnotationRight" + selectedProduct.code,
            arrayRight
          );

          canvas.remove(activeObject);
          canvas.renderAll();
        }
      }

      //saveCanvasData();
    });

    canvas.on("object:added", function () {
      if (!isRedoing) {
        h = [];
      }
      isRedoing = false;
    });

    var isRedoing = false;
    //@ts-ignore
    var h = [];
    //@ts-ignore
    document
      .getElementById("undo-right")
      .addEventListener("click", function () {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
        //saveCanvasData();
      });
    //@ts-ignore
    document
      .getElementById("redo-right")
      .addEventListener("click", function () {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
        //saveCanvasData();
      });

    //@ts-ignore
    document.addEventListener("keyup", ({ keyCode, ctrlKey }) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return;
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        if (canvas._objects.length > 0) {
          h.push(canvas._objects.pop());
          canvas.renderAll();
        }
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        if (h.length > 0) {
          isRedoing = true;
          //@ts-ignore
          canvas.add(h.pop());
        }
      }
    });

    canvas.on("selection:created", function (event: any) {
      // Get the selected object
      var selectedObject = event.target;

      // Get the reference to the select element
      const select = document.querySelector("#blend-mode-right");
      // Add onchange event listener to the select element
      //@ts-ignore
      select.onchange = function () {
        // Get the selected option value
        //@ts-ignore
        console.log(this.value);
        //@ts-ignore
        var blendMode = this.value;

        // Set the globalCompositeOperation property of the selected object
        //@ts-ignore
        selectedObject.set("globalCompositeOperation", blendMode);

        // Render the canvas to apply the changes
        canvas.renderAll();
      };
    });

    const handleButtonClickRight = () => {
      setActiveAnnotationRight(true);
      canvas.isDrawingMode = false;
      //@ts-ignore
      var text = document.getElementById("text-input-right").value;
      if (text) {
        var textObject = new fabric.IText(text, {
          left: 100,
          top: 100,
          // fill: colorInput,
          //@ts-ignore
          fontSize: 12,
        });

        var pin = new fabric.IText(annotation.toString(), {
          left: 100,
          top: 100,
          fill: "white",
          fontSize: 24,
          textBackgroundColor: "#9A9B46",
        });

        arrayRight.push({ id: annotation, text: textObject.text });
        canvas.add(pin);
        setArrayAnnotationBack(arrayRight);
        localStorage.setItem(
          "arrayAnnotationRight" + selectedProduct.code,
          arrayRight
        );
        annotation++;
        //saveCanvasData();
        //@ts-ignore
        document.getElementById("text-input-right").value = "";
        setActiveAnnotationRight(false);
      }
    };

    // Utilisez l'événement "change" pour les éléments de formulaire
    const colorPicker = document.getElementById("color-picker-right");
    //@ts-ignore
    colorPicker.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushSizeInput = document.getElementById("brush-size-right");
    //@ts-ignore
    brushSizeInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });

    const brushOpacityInput = document.getElementById("brush-opacity-right");
    //@ts-ignore
    brushOpacityInput.addEventListener("change", function () {
      //@ts-ignore
      brushToolButton.click(); // Déclenche le clic sur le bouton pour mettre à jour le pinceau
    });
    const addButton = addButtonRefRight.current;
    if (addButton) {
      //@ts-ignore
      addButton.addEventListener("click", handleButtonClickRight);
    }
    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (addButton) {
        //@ts-ignore
        addButton.removeEventListener("click", handleButtonClickRight);
      }
    };
  }, []);

  const handleImageClickRight = (imageUrl: any) => {
    if (canright) {
      var src = imageUrl;
      var reader = new FileReader();
      reader.onload = function (event) {
        //@ts-ignore
        var imgData = event.target.result;
        //@ts-ignore
        fabric.Image.fromURL(imgData, function (img) {
          img.set({
            left: 50,
            top: 50,
            scaleX: 0.5,
            scaleY: 0.5,
          });
          img.scale(0.1);
          canright.add(img);
          canright.setActiveObject(img);
          canright.renderAll();
          //saveCanvasData();
        });
      };
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.log("Error loading image:", error);
        });
    }
  };

  const uploadFileRight = (e: any) => {
    var generate_color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      //@ts-ignore

      imgObj.src = event.target.result;
      //@ts-ignore

      fabric.Image.fromObject(imgObj, function (img) {
        img.set({
          left: 50,
          top: 50,
          scaleX: 0.5,
          scaleY: 0.5,
        });
        img.scale(0.1);
        // img.set('globalCompositeOperation', 'soft-light');
        canright.add(img);
        canright.setActiveObject(img);
        canright.renderAll();
      });
    };
    reader.readAsDataURL(file);
    handleImageTypes({ file: e.target.files[0], color: generate_color });

    //saveCanvasData();
    handleCancelRight();
  };

  return (
    <>
      <div className="canvas-container" style={{ marginTop: "0px" }}>
        <div className="row">
          <div
            className="col-2 side-customization"
            style={{
              display: "grid",
              justifyContent: "center",
              backgroundColor: "#e0e0e0",
            }}
          >
            {!selectedProduct?.front_image ? (
              <>
                <Skeleton.Avatar active={true} shape={"square"} />
              </>
            ) : (
              <>
                {" "}
                <span>Front</span>
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    selectedProduct?.front_image
                  }
                  alt=""
                  className={"faceCustom"}
                  onClick={handleCanvasClickFront}
                  style={{
                    cursor: "pointer",
                    borderRadius: "16px",
                    border:
                      activeCanvasRight === false &&
                      activeCanvasFront === true &&
                      activeCanvasBack === false &&
                      activeCanvasLeft === false
                        ? "1px solid #09101A"
                        : "none",
                  }}
                />
              </>
            )}

            <br />
            <span>Back</span>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL + selectedProduct?.back_image
              }
              alt=""
              className={"faceCustom"}
              onClick={handleCanvasClickBack}
              style={{
                cursor: "pointer",
                borderRadius: "16px",
                border:
                  activeCanvasRight === false &&
                  activeCanvasFront === false &&
                  activeCanvasBack === true &&
                  activeCanvasLeft === false
                    ? "1px solid #09101A"
                    : "none",
              }}
            />

            <br />
            <span>Left</span>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                selectedProduct?.images[2]?.path
              }
              alt=""
              className={"faceCustom"}
              onClick={handleCanvasClickLeft}
              style={{
                cursor: "pointer",
                borderRadius: "16px",
                border:
                  activeCanvasRight === false &&
                  activeCanvasFront === false &&
                  activeCanvasBack === false &&
                  activeCanvasLeft === true
                    ? "1px solid #09101A"
                    : "none",
              }}
            />

            <br />
            <span>Right</span>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                selectedProduct?.images[3]?.path
              }
              alt=""
              width="200"
              onClick={handleCanvasClickRight}
              style={{
                cursor: "pointer",
                borderRadius: "16px",
                border:
                  activeCanvasRight === true &&
                  activeCanvasFront === false &&
                  activeCanvasBack === false &&
                  activeCanvasLeft === false
                    ? "1px solid #09101A"
                    : "none",
              }}
            />
          </div>

          <div className="col-10">
            <div className="row">
              <div className="col-12">
                {" "}
                <div
                  style={{
                    display:
                      activeCanvasFront === true && activeCanvasBack === false
                        ? "block"
                        : "none",
                    background: "#e0e0e0",
                    borderRadius: 16,
                  }}
                  className="p-2 text-center"
                >
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Select "}>
                      <button
                        className={
                          activeButton === 1
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(1)}
                        id="select-tool-front"
                      >
                        <SelectIcon></SelectIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Brush"}>
                      <button
                        className={
                          activeButton === 2
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(2)}
                        id="brush-tool-front"
                      >
                        <BrushIcon></BrushIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <span
                    className={
                      activeButton === 2 ? "option-display" : "option-hidden"
                    }
                  >
                    <span
                      className=""
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      {" "}
                      <Tooltip placement="top" title={"Brush Size"}>
                        <span className="mr-2">
                          <input
                            type="range"
                            min="1"
                            max="50"
                            id="brush-size-front"
                            defaultValue="25"
                            className="rangeColor"
                          />
                        </span>
                      </Tooltip>
                      <Tooltip placement="top" title={"Brush Opacity"}>
                        <select
                          id="brush-opacity-front"
                          style={{
                            border: "none",
                            outline: "none",
                            scrollBehavior: "smooth",
                          }}
                          className="select-opacity"
                        >
                          <option value="1">100%</option>
                          <option value="0.9">90%</option>
                          <option value="0.8">80%</option>
                          <option value="0.7">70%</option>
                          <option value="0.6">60%</option>
                          <option value="0.5">50%</option>
                          <option value="0.4">40%</option>
                          <option value="0.3">30%</option>
                          <option value="0.2">20%</option>
                          <option value="0.1">10%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>

                  <span className="mr-2">
                    <Tooltip placement="top" title={"Eraser "}>
                      <button
                        className={
                          activeButton === 3
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(3)}
                        id="eraser-tool-front"
                      >
                        <EraseIcon></EraseIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <Tooltip placement="top" title={"Erase Size"}>
                    <span
                      className={
                        activeButton === 3 ? "option-display" : "option-hidden"
                      }
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="eraser-size-front"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <span
                    className={
                      activeButton === 2 ? "option-display" : "option-hidden"
                    }
                  >
                    <Tooltip placement="top" title={"Color"}>
                      <button className="btn-tool-bar ">
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-front"
                        />
                      </button>
                    </Tooltip>
                  </span>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Base Color"}>
                    <span
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                      className="mr-2"
                    >
                      <ExposureIcon></ExposureIcon>{" "}
                      <input
                        type="range"
                        min="-0.5"
                        max="0.5"
                        step="0.01"
                        id="brightness-front"
                        defaultValue="0"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span className="mr-2">
                    {activeAnnotationFront && (
                      <>
                        <span className="mr-2">
                          <input
                            type="text"
                            id="text-input-front"
                            placeholder="Enter your annotation here"
                          />
                        </span>
                      </>
                    )}
                    <Tooltip placement="top" title={"Annotation"}>
                      <button
                        ref={addButtonRefFront}
                        id="add-text-front"
                        className="btn-tool-bar "
                      >
                        {activeAnnotationFront ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />
                          </>
                        ) : (
                          <>
                            <AnnotationIcon></AnnotationIcon>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Text"}>
                      <button
                        className={
                          activeButton === 7
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(7)}
                        id="add-comment-front"
                      >
                        <TextIcon></TextIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <span
                    className={
                      activeButton === 7 ? "option-display" : "option-hidden"
                    }
                  >
                    <Tooltip placement="top" title={"Color"}>
                      <button className="btn-tool-bar ">
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-front-text"
                        />
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span
                    style={{
                      border: "1px solid #EEF2F6",
                      padding: "10px",
                      borderRadius: "25px",
                      background: "white",
                    }}
                    className="mr-2"
                  >
                    <span className="mr-2" style={{ cursor: "pointer" }}>
                      <Tooltip placement="top" title={"Upload File"}>
                        <button onClick={showModal}>
                          <FileIcon></FileIcon>
                        </button>
                      </Tooltip>
                    </span>
                    <span className="mr-2">
                      <HrIcon></HrIcon>
                    </span>
                    <span className="mr-2">
                      <Tooltip placement="top" title={"Opacity file"}>
                        <select
                          id="blend-mode-front"
                          style={{ border: "none" }}
                        >
                          <option value="source-over">100%</option>
                          <option value="overlay">75%</option>
                          <option value="soft-light">50%</option>
                          <option value="lighten">25%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span className="mr-2">
                    <Tooltip placement="top" title={"Undo or Ctrl + Z"}>
                      <button
                        className={
                          activeButton === 4
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(4)}
                        id="undo-front"
                      >
                        <UndoIcon></UndoIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Redo or Ctrl + Y"}>
                      <button
                        className={
                          activeButton === 5
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(5)}
                        id="redo-front"
                      >
                        <RedoIcon></RedoIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Copy"}>
                      <button className="btn-tool-bar " id="copyButtonFront">
                        <CopyIcon></CopyIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Past"}>
                      <button
                        className={
                          activeButton === 6
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickFont(6)}
                        id="pasteButtonFront"
                      >
                        <PastIcon></PastIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Remove or Back espace"}>
                      <button
                        className={
                          activeButton === 8
                            ? "btn-tool-bar-active pr-3 pl-3"
                            : "btn-tool-bar pr-3 pl-3"
                        }
                        onClick={() => handleClickFont(8)}
                        id="remove-object-front"
                      >
                        <DeleteIcon></DeleteIcon>{" "}
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip
                      placement="top"
                      title={
                        "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                      }
                    >
                      <span
                        style={{
                          border: "1px solid #EEF2F6",
                          padding: "10px",
                          borderRadius: "25px",
                          background: "white",
                        }}
                      >
                        <ZoomIcon></ZoomIcon>
                        <span style={{ marginLeft: "20px" }}>
                          {(valueZoom * 100).toFixed(0)} %
                        </span>
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <div
                  style={{
                    display:
                      activeCanvasBack === true && activeCanvasFront === false
                        ? "block"
                        : "none",
                    background: "#e0e0e0",
                    borderRadius: 16,
                  }}
                  className="p-2 text-center"
                >
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Select"}>
                      <button
                        className={
                          activeButtonBack === 1
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(1)}
                        id="select-tool-back"
                      >
                        <SelectIcon></SelectIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <Tooltip placement="top" title={"Brush"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonBack === 2
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(2)}
                        id="brush-tool-back"
                      >
                        <BrushIcon></BrushIcon>
                      </button>
                    </span>
                  </Tooltip>

                  <span
                    className={
                      activeButtonBack === 2
                        ? "option-display"
                        : "option-hidden"
                    }
                  >
                    <span
                      className=""
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <Tooltip placement="top" title={"Brush Size"}>
                        <span className="mr-2">
                          <input
                            type="range"
                            min="1"
                            max="50"
                            id="brush-size-back"
                            defaultValue="25"
                            className="rangeColor"
                          />
                        </span>
                      </Tooltip>
                      <Tooltip placement="top" title={"Brush Opacity"}>
                        <select
                          id="brush-opacity-back"
                          style={{
                            border: "none",
                            outline: "none",
                            scrollBehavior: "smooth",
                          }}
                          className="select-opacity"
                        >
                          <option value="1">100%</option>
                          <option value="0.9">90%</option>
                          <option value="0.8">80%</option>
                          <option value="0.7">70%</option>
                          <option value="0.6">60%</option>
                          <option value="0.5">50%</option>
                          <option value="0.4">40%</option>
                          <option value="0.3">30%</option>
                          <option value="0.2">20%</option>
                          <option value="0.1">10%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>

                  <span className="mr-2">
                    <Tooltip placement="top" title={"Eraser "}>
                      <button
                        className={
                          activeButtonBack === 3
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(3)}
                        id="eraser-tool-back"
                      >
                        <EraseIcon></EraseIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <Tooltip placement="top" title={"Erase Size"}>
                    <span
                      className={
                        activeButtonBack === 3
                          ? "option-display"
                          : "option-hidden"
                      }
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="eraser-size-back"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Base Color"}>
                    <span
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                      className="mr-2"
                    >
                      <ExposureIcon></ExposureIcon>{" "}
                      <input
                        type="range"
                        min="-0.5"
                        max="0.5"
                        step="0.01"
                        id="brightness-back"
                        defaultValue="0"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>

                  <Tooltip placement="top" title={"Color"}>
                    <span
                      className={
                        activeButtonBack === 2 || activeButtonBack === 8
                          ? "option-display"
                          : "option-hidden"
                      }
                    >
                      <button className="btn-tool-bar ">
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-back"
                        />
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span className="mr-2">
                    {activeAnnotationBack && (
                      <>
                        <span className="mr-2">
                          <input
                            type="text"
                            id="text-input-back"
                            placeholder="Enter your annotation here"
                          />
                        </span>
                      </>
                    )}
                    <Tooltip placement="top" title={"Annotation"}>
                      <button
                        ref={addButtonRefBack}
                        id="add-text-back"
                        className="btn-tool-bar "
                      >
                        {activeAnnotationBack ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />
                          </>
                        ) : (
                          <>
                            <AnnotationIcon></AnnotationIcon>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </span>
                  <Tooltip placement="top" title={"Text"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonBack === 8
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(8)}
                        id="add-comment-back"
                      >
                        <TextIcon></TextIcon>
                      </button>
                    </span>

                    <span
                      className={
                        activeButtonBack === 8
                          ? "option-display"
                          : "option-hidden"
                      }
                    >
                      <button className="btn-tool-bar ">
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-back-text"
                        />
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span
                    style={{
                      border: "1px solid #EEF2F6",
                      padding: "10px",
                      borderRadius: "25px",
                      background: "white",
                    }}
                    className="mr-2"
                  >
                    <span className="mr-2" style={{ cursor: "pointer" }}>
                      <Tooltip placement="top" title={"Upload File"}>
                        <button onClick={showModalBack}>
                          <FileIcon></FileIcon>
                        </button>
                      </Tooltip>
                    </span>
                    <span className="mr-2">
                      <HrIcon></HrIcon>
                    </span>
                    <span className="mr-2">
                      <Tooltip placement="top" title={"Opacity file"}>
                        <select id="blend-mode-back" style={{ border: "none" }}>
                          <option value="source-over">100%</option>
                          <option value="overlay">75%</option>
                          <option value="soft-light">50%</option>
                          <option value="lighten">25%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Undo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonBack === 4
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(4)}
                        id="undo-back"
                      >
                        <UndoIcon></UndoIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Redo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonBack === 5
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(5)}
                        id="redo-back"
                      >
                        <RedoIcon></RedoIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Copy"}>
                      <button
                        className={
                          activeButtonBack === 6
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(6)}
                        id="copyButtonBack"
                      >
                        <CopyIcon></CopyIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Past"}>
                      <button
                        className={
                          activeButtonBack === 7
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickBack(7)}
                        id="pasteButtonBack"
                      >
                        <PastIcon></PastIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Remove or Back espace"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonBack === 9
                            ? "btn-tool-bar-active pr-3 pl-3"
                            : "btn-tool-bar pr-3 pl-3"
                        }
                        onClick={() => handleClickBack(9)}
                        id="remove-object-back"
                      >
                        <DeleteIcon></DeleteIcon>{" "}
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip
                      placement="top"
                      title={
                        "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                      }
                    >
                      <span
                        style={{
                          border: "1px solid #EEF2F6",
                          padding: "10px",
                          borderRadius: "25px",
                          background: "white",
                        }}
                      >
                        <ZoomIcon></ZoomIcon>
                        <span style={{ marginLeft: "20px" }}>
                          {(valueZoomBack * 100).toFixed(0)} %
                        </span>
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <div
                  style={{
                    display:
                      activeCanvasBack === false &&
                      activeCanvasFront === false &&
                      activeCanvasLeft == true
                        ? "block"
                        : "none",
                    background: "#e0e0e0",
                    borderRadius: 16,
                  }}
                  className="p-2 text-center"
                >
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Select"}>
                      <button
                        className={
                          activeButtonLeft === 1
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(1)}
                        id="select-tool-left"
                      >
                        <SelectIcon></SelectIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <Tooltip placement="top" title={"Brush"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonLeft === 2
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(2)}
                        id="brush-tool-left"
                      >
                        <BrushIcon></BrushIcon>
                      </button>
                    </span>
                  </Tooltip>

                  <span
                    className={
                      activeButtonLeft === 2
                        ? "option-display"
                        : "option-hidden"
                    }
                  >
                    <span
                      className=""
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <Tooltip placement="top" title={"Brush Size"}>
                        <span className="mr-2">
                          <input
                            type="range"
                            min="1"
                            max="50"
                            id="brush-size-left"
                            defaultValue="25"
                            className="rangeColor"
                          />
                        </span>
                      </Tooltip>
                      <Tooltip placement="top" title={"Brush Opacity"}>
                        <select
                          id="brush-opacity-left"
                          style={{
                            border: "none",
                            outline: "none",
                            scrollBehavior: "smooth",
                          }}
                          className="select-opacity"
                        >
                          <option value="1">100%</option>
                          <option value="0.9">90%</option>
                          <option value="0.8">80%</option>
                          <option value="0.7">70%</option>
                          <option value="0.6">60%</option>
                          <option value="0.5">50%</option>
                          <option value="0.4">40%</option>
                          <option value="0.3">30%</option>
                          <option value="0.2">20%</option>
                          <option value="0.1">10%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>

                  <span className="mr-2">
                    <Tooltip placement="top" title={"Eraser "}>
                      <button
                        className={
                          activeButtonLeft === 3
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(3)}
                        id="eraser-tool-left"
                      >
                        <EraseIcon></EraseIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <Tooltip placement="top" title={"Erase Size"}>
                    <span
                      className={
                        activeButtonLeft === 3
                          ? "option-display"
                          : "option-hidden"
                      }
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="eraser-size-left"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Color"}>
                    <span
                      className={
                        activeButtonLeft === 2 || activeButtonLeft === 8
                          ? "option-display"
                          : "option-hidden"
                      }
                    >
                      <button
                        className={
                          activeButtonLeft === 10
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(10)}
                      >
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-left"
                        />
                      </button>
                    </span>
                  </Tooltip>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Base Color"}>
                    <span
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                      className="mr-2"
                    >
                      <ExposureIcon></ExposureIcon>{" "}
                      <input
                        type="range"
                        min="-0.5"
                        max="0.5"
                        step="0.01"
                        id="brightness-left"
                        defaultValue="0"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span className="mr-2">
                    {activeAnnotationLeft && (
                      <>
                        <span className="mr-2">
                          <input
                            type="text"
                            id="text-input-left"
                            placeholder="Enter your annotation here"
                          />
                        </span>
                      </>
                    )}
                    <Tooltip placement="top" title={"Annotation"}>
                      <button
                        ref={addButtonRefLeft}
                        id="add-text-back"
                        className="btn-tool-bar "
                      >
                        {activeAnnotationLeft ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />
                          </>
                        ) : (
                          <>
                            <AnnotationIcon></AnnotationIcon>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </span>
                  <Tooltip placement="top" title={"Text"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonLeft === 8
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(8)}
                        id="add-comment-left"
                      >
                        <TextIcon></TextIcon>
                      </button>
                    </span>

                    <Tooltip placement="top" title={"Color"}>
                      <span
                        className={
                          activeButtonLeft === 8
                            ? "option-display"
                            : "option-hidden"
                        }
                      >
                        <button
                          className={
                            activeButtonLeft === 10
                              ? "btn-tool-bar-active"
                              : "btn-tool-bar"
                          }
                          onClick={() => handleClickLeft(10)}
                        >
                          <input
                            className="input-color"
                            type="color"
                            id="color-picker-left-text"
                          />
                        </button>
                      </span>
                    </Tooltip>

                    <span className="mr-2">
                      <HrIcon></HrIcon>
                    </span>

                    <span
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                      className="mr-2"
                    >
                      <span className="mr-2" style={{ cursor: "pointer" }}>
                        <Tooltip placement="top" title={"Upload File"}>
                          <button onClick={showModalLeft}>
                            <FileIcon></FileIcon>
                          </button>
                        </Tooltip>
                      </span>
                      <span className="mr-2">
                        <HrIcon></HrIcon>
                      </span>
                      <span className="mr-2">
                        <Tooltip placement="top" title={"Opacity file"}>
                          <select
                            id="blend-mode-left"
                            style={{ border: "none" }}
                          >
                            <option value="source-over">100%</option>
                            <option value="overlay">75%</option>
                            <option value="soft-light">50%</option>
                            <option value="lighten">25%</option>
                          </select>
                        </Tooltip>
                      </span>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <Tooltip placement="top" title={"Undo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonLeft === 4
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(4)}
                        id="undo-left"
                      >
                        <UndoIcon></UndoIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Redo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonLeft === 5
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(5)}
                        id="redo-left"
                      >
                        <RedoIcon></RedoIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Copy"}>
                      <button
                        className={
                          activeButtonLeft === 6
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(6)}
                        id="copyButtonLeft"
                      >
                        <CopyIcon></CopyIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Past"}>
                      <button
                        className={
                          activeButtonLeft === 7
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickLeft(7)}
                        id="pasteButtonLeft"
                      >
                        <PastIcon></PastIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <Tooltip placement="top" title={"Remove or Back espace"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonLeft === 9
                            ? "btn-tool-bar-active pr-3 pl-3"
                            : "btn-tool-bar pr-3 pl-3"
                        }
                        onClick={() => handleClickLeft(9)}
                        id="remove-object-left"
                      >
                        <DeleteIcon></DeleteIcon>{" "}
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip
                      placement="top"
                      title={
                        "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                      }
                    >
                      <span
                        style={{
                          border: "1px solid #EEF2F6",
                          padding: "10px",
                          borderRadius: "25px",
                          background: "white",
                        }}
                      >
                        <ZoomIcon></ZoomIcon>
                        <span style={{ marginLeft: "20px" }}>
                          {(valueZoomBack * 100).toFixed(0)} %
                        </span>
                      </span>
                    </Tooltip>
                  </span>
                </div>
                <div
                  style={{
                    display:
                      activeCanvasBack === false &&
                      activeCanvasFront === false &&
                      activeCanvasLeft == false &&
                      activeCanvasRight == true
                        ? "block"
                        : "none",
                    background: "#e0e0e0",
                    borderRadius: 16,
                  }}
                  className="p-2 text-center"
                >
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Select"}>
                      <button
                        className={
                          activeButtonRight === 1
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(1)}
                        id="select-tool-right"
                      >
                        <SelectIcon></SelectIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>
                  <Tooltip placement="top" title={"Brush"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonRight === 2
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(2)}
                        id="brush-tool-right"
                      >
                        <BrushIcon></BrushIcon>
                      </button>
                    </span>
                  </Tooltip>

                  <span
                    className={
                      activeButtonRight === 2
                        ? "option-display"
                        : "option-hidden"
                    }
                  >
                    <span
                      className=""
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <Tooltip placement="top" title={"Brush Size"}>
                        <span className="mr-2">
                          <input
                            type="range"
                            min="1"
                            max="50"
                            id="brush-size-right"
                            defaultValue="25"
                            className="rangeColor"
                          />
                        </span>
                      </Tooltip>
                      <Tooltip placement="top" title={"Brush Opacity"}>
                        <select
                          id="brush-opacity-right"
                          style={{
                            border: "none",
                            outline: "none",
                            scrollBehavior: "smooth",
                          }}
                          className="select-opacity"
                        >
                          <option value="1">100%</option>
                          <option value="0.9">90%</option>
                          <option value="0.8">80%</option>
                          <option value="0.7">70%</option>
                          <option value="0.6">60%</option>
                          <option value="0.5">50%</option>
                          <option value="0.4">40%</option>
                          <option value="0.3">30%</option>
                          <option value="0.2">20%</option>
                          <option value="0.1">10%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>

                  <span className="mr-2">
                    <Tooltip placement="top" title={"Eraser "}>
                      <button
                        className={
                          activeButtonRight === 3
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(3)}
                        id="eraser-tool-right"
                      >
                        <EraseIcon></EraseIcon>
                      </button>
                    </Tooltip>
                  </span>

                  <Tooltip placement="top" title={"Erase Size"}>
                    <span
                      className={
                        activeButtonRight === 3
                          ? "option-display"
                          : "option-hidden"
                      }
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                    >
                      <input
                        type="range"
                        min="1"
                        max="50"
                        id="eraser-size-right"
                        defaultValue="25"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>

                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Color"}>
                    <span
                      className={
                        activeButtonRight === 2 || activeButtonRight === 8
                          ? "option-display"
                          : "option-hidden"
                      }
                    >
                      <button
                        className={
                          activeButtonRight === 10
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(10)}
                      >
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-right"
                        />
                      </button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Base Color"}>
                    <span
                      style={{
                        border: "1px solid #EEF2F6",
                        padding: "10px",
                        borderRadius: "25px",
                        background: "white",
                      }}
                      className="mr-2"
                    >
                      <ExposureIcon></ExposureIcon>{" "}
                      <input
                        type="range"
                        min="-0.5"
                        max="0.5"
                        step="0.01"
                        id="brightness-right"
                        defaultValue="0"
                        className="rangeColor"
                      />
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span className="mr-2">
                    {activeAnnotationRight && (
                      <>
                        <span className="mr-2">
                          <input
                            type="text"
                            id="text-input-right"
                            placeholder="Enter your annotation here"
                          />
                        </span>
                      </>
                    )}
                    <Tooltip placement="top" title={"Annotation"}>
                      <button
                        ref={addButtonRefRight}
                        id="add-text-back"
                        className="btn-tool-bar "
                      >
                        {activeAnnotationRight ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />
                          </>
                        ) : (
                          <>
                            <AnnotationIcon></AnnotationIcon>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </span>
                  <Tooltip placement="top" title={"Text"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonRight === 8
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(8)}
                        id="add-comment-right"
                      >
                        <TextIcon></TextIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <Tooltip placement="top" title={"Color"}>
                    <span
                      className={
                        activeButtonRight === 2 || activeButtonRight === 8
                          ? "option-display"
                          : "option-hidden"
                      }
                    >
                      <button
                        className={
                          activeButtonRight === 10
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(10)}
                      >
                        <input
                          className="input-color"
                          type="color"
                          id="color-picker-right-text"
                        />
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <span
                    style={{
                      border: "1px solid #EEF2F6",
                      padding: "10px",
                      borderRadius: "25px",
                      background: "white",
                    }}
                    className="mr-2"
                  >
                    <span className="mr-2" style={{ cursor: "pointer" }}>
                      <Tooltip placement="top" title={"Upload File"}>
                        <button onClick={showModalRight}>
                          <FileIcon></FileIcon>
                        </button>
                      </Tooltip>
                    </span>
                    <span className="mr-2">
                      <HrIcon></HrIcon>
                    </span>
                    <span className="mr-2">
                      <Tooltip placement="top" title={"Opacity file"}>
                        <select
                          id="blend-mode-right"
                          style={{ border: "none" }}
                        >
                          <option value="source-over">100%</option>
                          <option value="overlay">75%</option>
                          <option value="soft-light">50%</option>
                          <option value="lighten">25%</option>
                        </select>
                      </Tooltip>
                    </span>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Undo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonRight === 4
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(4)}
                        id="undo-right"
                      >
                        <UndoIcon></UndoIcon>
                      </button>
                    </span>
                  </Tooltip>

                  <Tooltip placement="top" title={"Redo"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonRight === 5
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(5)}
                        id="redo-right"
                      >
                        <RedoIcon></RedoIcon>
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Copy"}>
                      <button
                        className={
                          activeButtonRight === 6
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(6)}
                        id="copyButtonRight"
                      >
                        <CopyIcon></CopyIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <Tooltip placement="top" title={"Past"}>
                      <button
                        className={
                          activeButtonRight === 7
                            ? "btn-tool-bar-active"
                            : "btn-tool-bar"
                        }
                        onClick={() => handleClickRight(7)}
                        id="pasteButtonRight"
                      >
                        <PastIcon></PastIcon>
                      </button>
                    </Tooltip>
                  </span>
                  <span className="mr-2">
                    <HrIcon></HrIcon>
                  </span>

                  <Tooltip placement="top" title={"Remove or Back espace"}>
                    <span className="mr-2">
                      <button
                        className={
                          activeButtonRight === 9
                            ? "btn-tool-bar-active  pr-3 pl-3"
                            : "btn-tool-bar pr-3 pl-3"
                        }
                        onClick={() => handleClickRight(9)}
                        id="remove-object-right"
                      >
                        <DeleteIcon></DeleteIcon>{" "}
                      </button>
                    </span>
                  </Tooltip>
                  <span className="mr-2">
                    <Tooltip
                      placement="top"
                      title={
                        "To zoom in, you need to use the mouse scroll wheel at the location where you want to zoom."
                      }
                    >
                      <span
                        style={{
                          border: "1px solid #EEF2F6",
                          padding: "10px",
                          borderRadius: "25px",
                          background: "white",
                        }}
                      >
                        <ZoomIcon></ZoomIcon>
                        <span style={{ marginLeft: "20px" }}>
                          {(valueZoomRight * 100).toFixed(0)} %
                        </span>
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
            <div className="row p-3 " id="capteur">
              <div className="col-12 " id="capteur_custom_front">
                <div>
                  <div className="row">
                    <div
                      className="col-2 "
                      style={{
                        display: activeCanvasFront ? "flex" : "none",
                      }}
                    >
                      <div id="annotationListFront">
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayFront.length == 0 && <></>}
                        {arrayFront.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        activeCanvasFront
                          ? "col-9 container active-border"
                          : "col-9 container inactive"
                      }
                      style={{
                        display: activeCanvasFront ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas id="canvas-font"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 " id="capteur_custom_back">
                <div>
                  <div className="row">
                    <div className="col-2">
                      <div
                        id="annotationListBack"
                        style={{
                          display: activeCanvasBack ? "block" : "none",
                        }}
                      >
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayBack.length == 0 && <></>}
                        {arrayBack.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        activeCanvasBack
                          ? "col-9 container active-border"
                          : "col-9 container inactive"
                      }
                      style={{
                        display: activeCanvasBack ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas
                        id="canvas-back"
                        className={activeCanvasBack ? "" : "canvas-back"}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 " id="capteur_custom_left">
                <div>
                  <div className="row">
                    <div className="col-2">
                      <div
                        id="annotationListLeft"
                        style={{
                          display: activeCanvasLeft ? "block" : "none",
                        }}
                      >
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayLeft.length == 0 && <></>}
                        {arrayLeft.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        activeCanvasLeft
                          ? "col-9 container active-border"
                          : "col-9 container inactive"
                      }
                      style={{
                        display: activeCanvasLeft ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas
                        id="canvas-left"
                        className={activeCanvasLeft ? "" : "canvas-left"}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 " id="capteur_custom_right">
                <div>
                  <div className="row">
                    <div className="col-2">
                      <div
                        id="annotationListRight"
                        style={{
                          display: activeCanvasRight ? "block" : "none",
                        }}
                      >
                        <span style={{ fontSize: "20px" }}> Annotation </span>
                        {arrayRight.length == 0 && <></>}
                        {arrayRight.map((item: any, index: any) => (
                          <div key={index}>
                            <span
                              style={{
                                backgroundColor: "#9A9B46",
                                color: "white",
                                padding: "2px",
                              }}
                            >
                              {item.id}
                            </span>
                            <span style={{ fontSize: "20px" }}>
                              {item.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        activeCanvasRight
                          ? "col-9 container active-border"
                          : "col-9 container inactive"
                      }
                      style={{
                        display: activeCanvasRight ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <canvas
                        id="canvas-right"
                        className={activeCanvasRight ? "" : "canvas-right"}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Add Patch"
        visible={open}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancel}
        footer={[
          <button key="back" onClick={handleCancel} style={{ borderRadius: 5 }}>
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClick(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancel();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFile(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      <Modal
        title="Add Patch"
        visible={openBack}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancelBack}
        footer={[
          <button
            key="back"
            onClick={handleCancelBack}
            style={{ borderRadius: 5 }}
          >
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClickBack(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancelBack();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClickBack(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFileBack(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      <Modal
        title="Add Patch"
        visible={openLeft}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancelLeft}
        footer={[
          <button
            key="back"
            onClick={handleCancelLeft}
            style={{ borderRadius: 5 }}
          >
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClickLeft(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancelLeft();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClickLeft(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFileLeft(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Modal
        title="Add Patch"
        visible={openRight}
        maskClosable={false}
        closable={false}
        centered
        onCancel={handleCancelRight}
        footer={[
          <button
            key="back"
            onClick={handleCancelRight}
            style={{ borderRadius: 5 }}
          >
            Close
          </button>,
        ]}
      >
        <div>Choose from our preselected list and customize your product</div>
        <Tabs defaultActiveKey="1" onChange={(key) => onChange(key)}>
          <Tabs.TabPane tab="Embellishment" key="1">
            <Select
              defaultValue="Please select option"
              style={{ width: "100%" }}
              onChange={handleChangeEmbellishment}
              options={
                embellishment &&
                embellishment.map((el: any) => {
                  return {
                    label: el.name,
                    value: el.id,
                  };
                })
              }
            />

            {selectedEmbellishment && (
              <div
                className="row m-2"
                style={{ maxHeight: "250px", overflowX: "auto" }}
              >
                {embellishment
                  .find(
                    (entity: any) => entity.id === Number(selectedEmbellishment)
                  )
                  .images.map((image: any) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #E3E8EF",
                          padding: "6px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          marginRight: "8px",
                        }}
                      >
                        <img
                          width={120}
                          height={120}
                          key={image.id}
                          src={process.env.REACT_APP_STORAGE_URL + image.image}
                          onClick={() => {
                            handleImageClickRight(
                              process.env.REACT_APP_STORAGE_URL + image.image
                            );
                            handleCancelRight();
                          }}
                          alt={image.name}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </Tabs.TabPane>
          {selectedProduct.fabric_type == "knit" && (
            <Tabs.TabPane tab="Washes" key="2">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWashes}
                options={
                  washes &&
                  washes.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWashes && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {washes
                    .find((entity: any) => entity.id === Number(selectedWashes))
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClickRight(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}
          {selectedProduct.fabric_type == "denim" && (
            <Tabs.TabPane tab="Whiskers" key="3">
              <Select
                defaultValue="Please select option"
                style={{ width: "100%" }}
                onChange={handleChangeWhisker}
                options={
                  whiskers &&
                  whiskers.map((el: any) => {
                    return {
                      label: el.name,
                      value: el.id,
                    };
                  })
                }
              />
              {selectedWhisker && (
                <div
                  className="row m-2"
                  style={{ maxHeight: "250px", overflowX: "auto" }}
                >
                  {whiskers
                    .find(
                      (entity: any) => entity.id === Number(selectedWhisker)
                    )
                    .images.map((image: any) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #E3E8EF",
                            padding: "6px",
                            borderRadius: "8px",
                            marginBottom: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <img
                            width={140}
                            height={140}
                            key={image.id}
                            src={
                              process.env.REACT_APP_STORAGE_URL + image.image
                            }
                            onClick={() =>
                              handleImageClick(
                                process.env.REACT_APP_STORAGE_URL + image.image
                              )
                            }
                            alt={image.name}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Upload File" key="4">
            <input
              type="file"
              style={{ cursor: "pointer" }}
              onChange={(e) => uploadFileRight(e)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
export { CustomizationProduct };
